export class LockingDeviceParamsDto {
  uuid: string | undefined;
  openingDuration: number;
  beeper: boolean;
  officeFunction: boolean;
  officeTimeout: number | undefined;
  openTimeModelUuid: string | undefined;
  closeTimeModelUuid: string | undefined;
  variantId: number;
  loggingId: number | undefined;
  paramsGroupId: number | undefined;
  paramsGroupName: string | undefined;

  constructor(uuid: string | undefined,
              openingDuration: number,
              beeper: boolean,
              officeFunction: boolean,
              officeTimeout: number | undefined,
              openTimeModelUuid: string | undefined,
              closeTimeModelUuid: string | undefined,
              variantId: number,
              loggingId: number | undefined,
              paramsGroupId: number | undefined,
              paramsGroupName: string | undefined,
  ) {
    this.uuid = uuid;
    this.openingDuration = openingDuration;
    this.beeper = beeper;
    this.officeFunction = officeFunction;
    this.officeTimeout = officeTimeout;
    this.openTimeModelUuid = openTimeModelUuid;
    this.closeTimeModelUuid = closeTimeModelUuid;
    this.variantId = variantId;
    this.loggingId = loggingId;
    this.paramsGroupId = paramsGroupId;
    this.paramsGroupName = paramsGroupName;
  }

  static emptyLockingDeviceParamsDto(): LockingDeviceParamsDto {
    return new LockingDeviceParamsDto(undefined, 5, false, false, 0, undefined, undefined, 1, undefined, undefined, undefined)
  }

}
