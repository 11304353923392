import {Component, EventEmitter, Input, Output} from '@angular/core';

import {ApiService} from "../../../../../core/services/api-service/api.service";
import {FormatterService} from "../../../../../core/services/formatter-service/formatter.service";
import {TranslateService} from "@ngx-translate/core";
import {TimeModelDto} from "../../../../../shared/entities/TimeModel/TimeModelDto";
import {SessionManager} from "../../../../../core/services/auth-service/support-services/SessionManager";
import {take} from "rxjs";
import {LockingDeviceParamsDto} from "../../../../../shared/entities/locking-device/LockingDeviceParamsDto";
import {VARIANT} from "../../../../../shared/lookup/variant.lookup";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";
import {ChangeDetectorChanges} from "../../../../../shared/util/change-detector/ChangeDetectorChanges";

/*
 * IMPORTANT
 * The Locking Device Params Form page is a test page
 * in which the 'form' was removed in favor of the newly created
 * ChangeDetectorValue class.
 *
 * WHY?
 * The form validation is included in 2 Pages
 * In one its working totally fine
 * In one changes are not detected correctly
 */

@Component({
  selector: 'app-locking-device-params-form',
  templateUrl: './locking-device-params-form.component.html',
  styleUrls: ['./locking-device-params-form.component.scss']
})
export class LockingDeviceParamsFormComponent {
  @Output() onChanges = new EventEmitter<boolean>();
  @Input() accessReadonly = false;

  openingDuration: ChangeDetectorValue = new ChangeDetectorValue(0, () => {this.onChanges.emit()});
  beeper: ChangeDetectorValue = new ChangeDetectorValue(false, () => {this.onChanges.emit()});
  officeFunction: ChangeDetectorValue = new ChangeDetectorValue(false, () => {this.onChanges.emit()});
  variantId: ChangeDetectorValue = new ChangeDetectorValue(0, () => {this.onChanges.emit()});
  loggingId: ChangeDetectorValue = new ChangeDetectorValue(0, () => {this.onChanges.emit()});
  openTimeModel: ChangeDetectorValue = new ChangeDetectorValue('0', () => {this.onChanges.emit()});
  closeTimeModel: ChangeDetectorValue = new ChangeDetectorValue('0', () => {this.onChanges.emit()});
  officeTime: ChangeDetectorValue = new ChangeDetectorValue({hour: 0, minute: 0}, () => {this.onChanges.emit()});

  get isReadonly() {
    return this.accessReadonly || this.paramsManagedByGroup;
  }

  paramsManagedByGroup = false;
  paramsGroup?: { id: number, name: string };

  isOnlineAvailable = false;
  showLogging = false;
  timeModelsData: TimeModelDto[] = [];

  _selectedItem?: LockingDeviceParamsDto = undefined;

  @Input() set lockingDeviceParamsAndTimeModels(uuid: string) {
    this.apiService.timeModel.getAll().then(observer => {
      observer.pipe(take(1)).subscribe(timeModels => {
        this.patchTimeModelValues(timeModels);
        if (uuid == '') {
          return;
        }
        this.apiService.lockingDevice.getParameters(uuid)
          .then(item => item.pipe(take(1)).subscribe(lockingDeviceDto => this.patchParamValues(lockingDeviceDto)));
      });
    });
  }

  @Input() set lockingGroupDeviceParamsAndTimeModels(lockingDeviceParamsDto: LockingDeviceParamsDto | undefined) {
    this.apiService.timeModel.getAll().then(observer => {
      observer.pipe(take(1)).subscribe(timeModels => {
        this.patchTimeModelValues(timeModels);
        this.patchParamValues(lockingDeviceParamsDto);
      });
    });
  }

  private patchTimeModelValues(timeModels: TimeModelDto[]) {
    this.timeModelsData = [TimeModelDto.zeroTimeModelDtoWithName(this.translate.instant("LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.NONE")),...timeModels];
  }

  private patchParamValues(item: LockingDeviceParamsDto | undefined) {
    if (item == null) {
      return;
    }

    this._selectedItem = item;

    this.openingDuration = new ChangeDetectorValue(item.openingDuration, () => {this.onChanges.emit()},
      new Map<string, (input: any) => boolean>()
        .set('required', (input: any) => {
          return input != null && (input as number) != 0;
      }).set('min', (input: any) => {
        return input != null && (input as number) >= 2;
      }).set('max', (input: any) => {
        return input != null && (input as number) <= 180;
      })
    );
    this.beeper = new ChangeDetectorValue(item.beeper, () => {this.onChanges.emit()});
    this.officeFunction = new ChangeDetectorValue(item.officeFunction, () => {this.onChanges.emit()});
    this.variantId = new ChangeDetectorValue(item.variantId, () => {this.onChanges.emit()});
    this.loggingId = new ChangeDetectorValue(item.loggingId || 0, () => {this.onChanges.emit()});
    this.openTimeModel = new ChangeDetectorValue(`${item.openTimeModelUuid || '0'}`, () => {this.onChanges.emit()},
      new Map<string, (input: any) => boolean>()
        .set('valid', (input: any) => {
          return input != this.closeTimeModel.value || (`${input}` == '0' || input == '');
      })
    );
    this.closeTimeModel = new ChangeDetectorValue(`${item.closeTimeModelUuid || '0'}`, () => {this.onChanges.emit()},
      new Map<string, (input: any) => boolean>()
      .set('valid', (input: any) => {
        return input != this.openTimeModel.value || (`${input}` == '0' || input == '');
      })
    );
    this.officeTime = new ChangeDetectorValue(this.formatter.formatToNgbTimePicker(item.officeTimeout || 0), () => {this.onChanges.emit()},
      new Map<string, (input: any) => boolean>()
        .set('valid', (input: any) => {
          return !(input.hour == 0 && input.minute == 0 && this.officeFunction.value == true);
        })
    );


    this.paramsManagedByGroup = item.paramsGroupId != null;
    if (this.paramsManagedByGroup) {
      this.paramsGroup = {
        id: item.paramsGroupId as number,
        name: item.paramsGroupName as string,
      };
    } else {
      this.paramsGroup = undefined;
    }
    this.showLogging = item.loggingId != null;
  }

  // Forms
  isLicenseBusiness: boolean;

  constructor(
    private apiService: ApiService,
    private formatter: FormatterService,
    private translate: TranslateService
  ) {
    this.isOnlineAvailable = apiService.lockingDevice.isOnlineAvailable()
    this.isLicenseBusiness = SessionManager.getInstance().isLicenseBusiness;
  }

  get changeDetectorChanges(): ChangeDetectorChanges {
    return new ChangeDetectorChanges(
      this.beeper,
      this.officeFunction,
      this.variantId,
      this.loggingId,
      this.openTimeModel,
      this.closeTimeModel,
      this.openingDuration,
      this.officeTime.hasChanges && this.officeFunction.value == true,
    )
  }

  getLockingDeviceParamsDto(): LockingDeviceParamsDto | undefined {
    if (this._selectedItem === undefined) {
      return undefined;
    }
    // update values
    this._selectedItem.openingDuration = this.openingDuration.value;
    this._selectedItem.officeTimeout = this.formatter.formatFromNgbTimePicker(this.officeTime.value);
    this._selectedItem.beeper = this.beeper.value;
    this._selectedItem.officeFunction = this.officeFunction.value;
    this._selectedItem.variantId = this.variantId.value;
    this._selectedItem.openTimeModelUuid = this.openTimeModel.value != '0' ? this.openTimeModel.value : null;
    this._selectedItem.closeTimeModelUuid = this.closeTimeModel.value != '0' ? this.closeTimeModel.value : null;

    return this._selectedItem;
  }

  getGroupsLockingDeviceParamsDto(): LockingDeviceParamsDto {
    if (this._selectedItem === undefined) {
      this._selectedItem = LockingDeviceParamsDto.emptyLockingDeviceParamsDto();
    }
    return this.getLockingDeviceParamsDto()!;
  }

  get variantForTranslation(): string {
    return `LOCKING_DEVICES.DEVICES.VARIANT.${[...VARIANT.filter(value => `${this.variantId.value}` == `${value.id}`),VARIANT[0]][0].value.toUpperCase()}`;
  }

  timeModelName(uuid: string | undefined): string {
    return this.timeModelsData.find((t) => t.uuid === uuid)?.shortName ||
      this.translate.instant("LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.NONE");
  }

  protected readonly VARIANT = VARIANT;
  protected readonly parseInt = parseInt;
}

// <a routerLink="/locking-devices/params-groups/{{paramsGroup.id}}"> {{paramsGroup.name}} </a>
