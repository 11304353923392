import {EventEmitter} from "@angular/core";
import {Subscription} from "rxjs";
import {cloneDeep} from 'lodash';
import {FormatterService} from "../../../core/services/formatter-service/formatter.service";

export class ChangeDetectorValue {
  private subscription?: Subscription;
  private _initialValue: any | null;
  private valueChange: EventEmitter<any> = new EventEmitter<any>();
  private validators?: Map<string, ((input: any) => boolean)>;

  constructor(public _value: any | null,
              public changeCallback: (changed: boolean) => void = () => {},
              validators?: Map<string, ((input: any) => boolean)>) {
    if (_value == null) {
      this.initialValue = undefined;
    } else {
      this.initialValue = cloneDeep(_value);
    }
    this.validators = validators;
    this.subscription = this.valueChange.subscribe((val => {
      this.changeCallback(this.hasChanges)
    }));

    if (this._value && this._value.hasOwnProperty('valueChange')) {
      this._value.valueChange.subscribe((val: any) => {
        this.valueChange.emit(this.hasChanges);
      });
    }
  }

  get isValid(): boolean {
    let valid: boolean = true;
    this.validators?.forEach(validator => {
      if (!validator(this.value)) {
        valid = false;
      }
    });
    return valid;
  }

  isSpecificValid(...ids: string[]): boolean {
    let valid: boolean = true;
    for (let i = 0; i < ids.length; i++) {
      if (this.validators?.has(ids[i]) && !this.validators?.get(ids[i])!(this.value)) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  isSpecificGroupValid(groupId: string): boolean {
    let valid: boolean = true;
    this.validators?.forEach((validator,key) => {
      if (key.includes(groupId) && !this.isSpecificValid(key)) {
        valid = false;
      }
    });
    return valid;
  }

  hasSpecificChanges(fieldname: string): boolean {
    if (this._value && typeof this._value == 'object') {
      if (this._value.hasOwnProperty(fieldname)) {
        return this.initialValue == undefined ||
          !this.initialValue.hasOwnProperty(fieldname) ||
          this.toString(this._value[fieldname]) != this.toString(this.initialValue[fieldname]);
      }
    }
    if (fieldname.startsWith('_')) {
      return false;
    }
    return this.hasSpecificChanges(`_${fieldname}`);
  }

  private toString(value: any): string {
    switch (typeof value) {
      case "string":
        return value as string;
      case "boolean":
        return value as boolean ? 'true' : 'false';
      case "number":
        return `${value}`;
      case "bigint":
        return `${value}`;
      case "object":
        if (this.isFieldUnwanted(value)) {
          return '';
        }
        if (value instanceof Set) {
          value = Array.from(value).sort((a, b) => a.localeCompare(b));
        }
        return JSON.stringify(value);
      default:
        return '';
    }
  }

  get hasChanges(): boolean {
    if ((this.value == null && this.initialValue != null) || (this.value != null && this.initialValue == null)) {
      return true;
    }

    if (typeof this.initialValue == 'object' && typeof this.value == 'object') {
      for (let key in this.value) {
        if (this.initialValue.hasOwnProperty(key)) {
          if (this.toString(this.initialValue[key]) != this.toString(this.value[key])) {
            return true;
          }
        } else {
          return true;
        }
      }
      if (this.value instanceof Set) {
        return (this.toString(this.initialValue) != this.toString(this.value))
      }
    }
    return (this.toJsonString(this.initialValue) != this.toJsonString(this.value));
  }

  get value(): any | null {
    return this._value;
  }

  set value(value: any | null) {
    this._value = value;
    this.valueChange.emit(value == null ? true : value);
  }

  reset(value?: any | null, newChangeCallback?: (changed: boolean) => void) {
    if (value) {
      this._value = cloneDeep(value);
      this.initialValue = cloneDeep(value);
    } else {
      this.initialValue = cloneDeep(this._value);
    }
    if (newChangeCallback) {
      this.changeCallback = newChangeCallback;
    }
    this.valueChange = new EventEmitter<any>();
    this.subscription?.unsubscribe();
    this.subscription = this.valueChange.subscribe((() => {
      this.changeCallback(this.hasChanges)
    }));
  }

  appendCallback(newChangeCallback: (changed: boolean) => void) {
    this.changeCallback = () => {
      this.changeCallback(this.hasChanges);
      newChangeCallback(this.hasChanges);
    }
    this.subscription = this.valueChange.subscribe((() => {
      this.changeCallback(this.hasChanges)
    }));
  }

  static createNumberValidatorMinMaxRequired(name: string, min: number, max: number): Map<string, (input: any) => boolean> {
    return new Map<string, (input: any) => boolean>()
      .set(`${name}Empty`, (input: any) => {
        return ((input[`${name}`]) + '').replace(' ', '').length != 0;
      }).set(`${name}Required`, (input: any) => {
        return ((input[`${name}`]) + '').length != 0;
      }).set(`${name}Min`, (input: any) => {
      switch (typeof input[`${name}`]) {
        case 'number':
          return (input[`${name}`] as number) >= min;
        case 'string':
          return (input[`${name}`] + '').length >= min;
      }
      return input[`${name}`] >= min;
    }).set(`${name}Max`, (input: any) => {
        switch (typeof input[`${name}`]) {
          case 'number':
            return (input[`${name}`] as number) <= max;
          case 'string':
            return (input[`${name}`] + '').length <= max;
        }
      return input[`${name}`] <= max;
    });
  }

  static mergeValidatorMaps(first: Map<string, (input: any) => boolean>, ...maps: Map<string, (input: any) => boolean>[]): Map<string, (input: any) => boolean> {
    maps.forEach(map => {
      map.forEach((value, key) => {
        first.set(key, value);
      });
    });
    return first;
  }

  get initialValue(): any {
    return this._initialValue;
  }

  private set initialValue(value: any) {
    this._initialValue = value;
  }

  private toJsonString(value: any): string {
    return JSON.stringify(value, (key, value) => {
      if (this.isFieldUnwanted(value)) {
        return undefined;
      }
      return value;
    });
  }

  private isFieldUnwanted(field: any): boolean {
    if (field != null) {
      return field instanceof EventEmitter || field instanceof Subscription || field instanceof FormatterService;
    }
    return false;
  }
}

export interface ChangeDetectorModel {
  valueChange: EventEmitter<any>;
  validators(): Map<string, (input: any) => boolean>;
  set values(values: any);
}
