import {Component, EventEmitter, Input, Output} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChangeDetectorForm} from "../../../../shared/util/change-detector/ChangeDetectorForm";
import {debounceTime, Subject} from "rxjs";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {AccessProfileDto} from "../../../../shared/entities/accessProfile/AccessProfileDto";
import {UpdateAccessProfileDto} from "../../../../shared/entities/accessProfile/UpdateAccessProfileDto";
import {AccessProfileDetailDto} from "../../../../shared/entities/accessProfile/AccessProfileDetailDto";
import {ACCESS_TYPE} from "../../../../shared/lookup/access.lookup";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
  selector: 'app-access-profile-form',
  templateUrl: './access-profile-form.component.html',
  styleUrls: ['./access-profile-form.component.scss']
})
export class AccessProfileFormComponent {
  _accessProfileItem?: ChangeDetectorValue;

  accessProfileName: string = '';
  accessProfileImmutable: boolean = false;

  validatorMaxLength32: number = 32;
  validatorMaxLength64: number = 64;

  @Input() set accessProfile(accessProfile: ChangeDetectorValue) {
    if (accessProfile == null || accessProfile.value == undefined) return;

    this._accessProfileItem = accessProfile;
  }

  constructor() {
  }

  reset() {
    this._accessProfileItem?.reset();
  }

  // Access
  @Input() accessReadonly: boolean = false;
  @Input() licenseTypeId: number = 1;
  @Input() licenseNotValid: boolean = false;

  get accessReadonlyGetter(): boolean {
    return this.accessReadonly || this.licenseNotValid || this.accessProfileImmutable;
  }

  get changed(): boolean {
    return this._accessProfileItem?.hasChanges == true;
  }

  protected readonly ACCESS_TYPE = ACCESS_TYPE;
}
