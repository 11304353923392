import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {SelectListItem} from "../../../../../shared/select-list/SelectListItem";
import {ApiService} from "../../../../../core/services/api-service/api.service";
import {FormatterService} from "../../../../../core/services/formatter-service/formatter.service";
import {SelectListComponent} from "../../../../../shared/select-list/select-list.component";
import {firstValueFrom} from "rxjs";
import {LockingMediaDto} from "../../../../../shared/entities/LockingMediaDto";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
  selector: 'app-user-locking-media-form',
  templateUrl: './user-locking-media-form.component.html',
  styleUrls: ['./user-locking-media-form.component.scss']
})
export class UserLockingMediaFormComponent {

  @ViewChild(SelectListComponent) selectListComponent!: SelectListComponent

  items: SelectListItem[] = [];
  selectedItems = new Set<string>()

  @Input() canChangeUserAccessSettings = true;
  @Input() licenseExpired = false;

  private _userLockingMedia: ChangeDetectorValue = new ChangeDetectorValue(['']);
  @Output() userLockingMediaChange = new EventEmitter<ChangeDetectorValue>();
  get userLockingMedia(): ChangeDetectorValue {
    return this._userLockingMedia;
  }
  @Input() set userLockingMedia(userLockingMedia: ChangeDetectorValue) {
    this.items = [];
    this.selectedItems = new Set<string>();
    this._userLockingMedia = userLockingMedia;
    this._userLockingMedia.reset();

    this.apiService.lockingMedia.getAll().then(observe => observe.subscribe({
      next: async allLockingMedia => {
        this.userLockingMediaSelectedItemsHelper(userLockingMedia.value as string[]);
        this.userLockingMediaItemsHelper(allLockingMedia,
          await firstValueFrom(await this.apiService.lockingMedia.getAssignable()), userLockingMedia.value as string[]);
      }
    }));
  }

  private userLockingMediaSelectedItemsHelper(userLockingMedia: string[] | null) {
    if (userLockingMedia == null || userLockingMedia.length == 0) {
      return;
    }
    this.selectedItems = new Set<string>(userLockingMedia);
  }

  private userLockingMediaItemsHelper(allLockingMedia: LockingMediaDto[], assignableLockingMedia: LockingMediaDto[], userLockingMedia: string[]) {
    // Setting addable items
    // Because of the filter logic inside the applist component, in order to display recently added devices, we must add
    // the devices that are already assigned to the user
    this.items = (allLockingMedia.filter(lockingMedia =>
      assignableLockingMedia.find(lockMedia => lockMedia.uuid == lockingMedia.uuid) ||
      userLockingMedia.find(uuid => uuid == lockingMedia.uuid)) || []).map<SelectListItem>((entry: LockingMediaDto) => {
      const item = new SelectListItem(entry.uuid, entry.displayUid)
        .setImage(`assets/ces/locking-media/${this.apiService.lockingMedia.getImageNameForMediaType(entry.implementationId)}.svg`);
      const assignedUsers: readonly string[] = entry.assignedUsers || [];
      if (assignedUsers.length != 0) {
        item.addInfo(this.formatter.formatArray(assignedUsers));
      }
      return item;
    });
  }

  constructor(
    private apiService: ApiService,
    private formatter: FormatterService
  ) {
  }

  onSelectionChanges(c: any) {
    this._userLockingMedia.value = Array.from(this.selectListComponent.selectedItems);
  }

  reset() {
    this.items = [];
    this.selectedItems.clear();
    this.selectListComponent.resetChangeDetection();
  }
}
