export interface Action{}

// LIST ACTIONS
export class ListAction implements Action {
  constructor(
    public key: string,
    public callback: () => void,
    public selectAction: boolean = false,
    public actionIcon?: string) { }
}

export class ListDeleteAction extends ListAction {
  constructor(callback:() => void = () => {}) {
    super("BUTTON.DELETE", callback, false, "mdi mdi-delete-outline")
  }
}

export class ListEditAction extends ListAction {
  constructor(callback:() => void = () => {}) {
    super("BUTTON.EDIT", callback, true, "mdi mdi-pencil-outline")
  }
}

export class ListViewAction extends ListAction {
  constructor(callback:() => void = () => {}) {
    super("BUTTON.VIEW", callback, true, "mdi mdi-eye-outline")
  }
}

// ITEM ACTIONS
export class ItemAction {

  public disabled = false

  constructor(
    public key: string,
    public callback: () => void,
    public returnAction: boolean = false,
    public styleClasses?: string,
    public icon?: string) {
  }

  setReturnAction(returnAction: boolean): ItemAction {
    this.returnAction = returnAction
    return this
  }

  setStyleClasses(styleClasses?: string): ItemAction {
    this.styleClasses = styleClasses
    return this
  }

  setIcon(icon?: string): ItemAction {
    this.icon = icon
    return this
  }
}

export class ItemSaveAction extends ItemAction {
  constructor(callback:() => void = () => {}) {
    super("BUTTON.SAVE", callback, false, "btn-outline-primary", "mdi mdi-content-save-outline")
  }
}

export class ItemDeleteAction extends ItemAction {
  constructor(callback:() => void = () => {}) {
    super("BUTTON.DELETE", callback, false, "btn-outline-danger", "mdi mdi-delete-outline")
  }
}

// MULTI LIST ACTIONS
export class MultiListAction implements Action{
  constructor(
    public key: string,
    public styleClasses?: string,
    public icon?: string) {}
}

export class MultiDeleteAction extends MultiListAction {
  constructor() {
    super("BUTTON.MULTI.DELETE", "btn-danger", "mdi mdi-delete-outline");
  }
}
