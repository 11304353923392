import {ListAction} from "../models/Actions";
import {Subject} from "rxjs";

export class ItemListItem<T> {

  public actions: ListAction[] = []
  public image?: any
  public overlay?: string
  public selectable?: boolean = true
  public info: string[] = []
  public badges: {icon: string, style: string, description?: string}[] = []
  public readonly?: boolean = false;

  constructor(
    public uuid: string,
    // public id: string,
    public name: string,
    public item: T
  ) {}

  addAction(action: ListAction): ItemListItem<T> {
    this.actions?.push(action)
    return this
  }

  addInfo(...info: string[]): ItemListItem<T> {
    this.info.push(...info)
    return this
  }

  setImage(image: any): ItemListItem<T> {
    this.image = image
    return this
  }

  setReadonly(readonly: boolean): ItemListItem<T> {
    this.readonly = readonly;
    return this;
  }

  setSelectable(selectable: boolean): ItemListItem<T> {
    this.selectable = selectable
    return this
  }

  setOverlayIcon(iconClass: string): ItemListItem<T> {
    this.overlay = iconClass
    return this
  }

  addBadge(icon: string, style: string, description?: string): ItemListItem<T> {
    this.badges.push({icon: icon, style: style, description: description})
    return this
  }

}

export interface ItemListMapper<T> {
  mapToItemList(item: T): ItemListItem<T>
}

export class ItemListFilter<T> {

  private readonly _filter: (item: T, listItem: ItemListItem<T>) => boolean
  public readonly onUpdate = new Subject() // subscription based updates to ensure performance

  constructor(filter: (item: T, listItem: ItemListItem<T>) => boolean = () => true) {
    this._filter = filter
  }

  filterListItem(item: T, listItem: ItemListItem<T>): boolean {
    return this._filter(item, listItem)
  }

  /**
   * Trigger updates on subsribers after search has changed to ensure performance
   */
  public triggerItemUpdate() {
    this.onUpdate.next(null)
  }
}
