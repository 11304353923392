import {SessionManager} from "./auth-service/support-services/SessionManager";
import {NodeManager} from "./auth-service/support-services/NodeManager";

import {Router} from "@angular/router";
import {NodeConfiguration, NodeConfigurationOld} from "../interfaces/nodeConfiguration";
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ToastService} from "../../shared/notification/toast/toast.service";

export abstract class CESService {
  protected sessionManager = SessionManager.getInstance()
  protected nodeManager = NodeManager.getInstance()

  protected constructor(protected router: Router, protected httpClient: HttpClient, protected notification: ToastService) {
  }

  async getMasterNodeJSON(): Promise<Observable<HttpResponse<NodeConfiguration | NodeConfigurationOld>>> {
    return this.httpClient.get<NodeConfiguration | NodeConfigurationOld>('settings/config.json', {observe: 'response'});
  }

  private async requestDataNode(system: string): Promise<Observable<HttpResponse<NodeConfiguration>>> {
    return this.httpClient.get<NodeConfiguration>(`${environment.masterNodeScheme}://${environment.masterNodeUrl}${environment.masterNodeBasePath}system/${system}`, {observe: 'response'});
  }

  protected async nodeRequest(system: string): Promise<NodeConfiguration | undefined> {
    const nodeConfig = await (await this.requestDataNode(system)).toPromise();
    const body: NodeConfiguration | null | undefined = nodeConfig?.body;
    if (nodeConfig?.ok && body != null) {
      this.nodeManager.currentUsedSystem = system;
      this.nodeManager.onNewConfig(`${body.scheme}://${body.host}`, body.apiKey, body.path);
      return body;
    }
    return undefined;
  }

  protected async buildUrl(system: string | undefined, path: string): Promise<string> {
    let node: NodeConfiguration | undefined = this.nodeManager.dataNodeConfiguration;
    if (system == undefined) {
      system = this.nodeManager.currentUsedSystem;
    }
    if (system != '' && (this.nodeManager.currentUsedSystem != system || this.nodeManager.dataNodeConfiguration == undefined)) {
      node = await this.nodeRequest(system);
    }
    if (node === undefined || node === null) {
      return '';
    }
    const basePath: string = `${node.scheme}://${node.host}${node.path}`;
    if (path.startsWith('/')) {
      return `${basePath}${path}`;
    }
    return `${basePath}/${path}`;
  }

  protected isLicenseValid(): boolean {
    return !this.sessionManager.isLicenseExpired;
  }

  private async navigateToPage(path: string) {
    await this.router.navigateByUrl(path);
  }

  // Helper
  get canAdd(): boolean {
    return this.sessionManager.isAdminOrHigher && this.isLicenseValid();
  }

  get canChange(): boolean {
    return this.sessionManager.isAdminOrHigher && this.isLicenseValid();
  }

  get canDelete(): boolean {
    return this.sessionManager.isAdminOrHigher && this.isLicenseValid();
  }

  get accessIsReadOnly(): boolean {
    return !this.sessionManager.isAdminOrHigher || !this.isLicenseValid();
  }

}
