import {jwtDecode, JwtPayload} from "jwt-decode";
import {Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenHelper {
  private readonly fourMinutes: number = 240;
  private readonly validityToWait: number = (2 / 3);
  private _newToken: JwtPayload | null | undefined;
  private validityDuration: number = this.fourMinutes;
  private _timeToWait: number = 1000;
  private _expireAt: number = new Date().getTime();

  public $expireAt: Subject<string> = new Subject<string>();
  public subjectTriggered: boolean = false;
  public token: string | undefined | null

  private static instance: RefreshTokenHelper;

  static getInstance(token: string | undefined | null): RefreshTokenHelper {
    if (this.instance == undefined) {
      this.instance = new RefreshTokenHelper();
      this.instance.token = token;
    }
    this.instance._newToken = this.instance.extractToken(token);
    return this.instance;
  }

  private constructor() {
  }

  public redeclareToken(token: string | undefined | null, sessionTokenUsed?: boolean) {
    this._newToken = this.extractToken(token, sessionTokenUsed);
  }

  extractToken(token: string | undefined | null, sessionTokenUsed?: boolean): JwtPayload | null {
    if (token && token != this.token) {
      this.token = token;
      const newTokenPayload: JwtPayload = jwtDecode(token);
      if (newTokenPayload.exp /*&& Math.floor(Date.now() / 1000) < newTokenPayload.exp*/) {
        if (newTokenPayload.iat) {
          this.validityDuration = newTokenPayload.exp - newTokenPayload.iat;
          this._timeToWait = (this.validityDuration * this.validityToWait) * 1000;
        } else {
          this._timeToWait = this.fourMinutes * 1000;
        }
        this._expireAt = new Date().getTime() + this._timeToWait;
        if (sessionTokenUsed) {
          const offset: number = Math.floor((new Date().getTime() + this._timeToWait - this._expireAt) / 1000);
          this._timeToWait -= offset;
          if (this._timeToWait < 0) {
            return null;
          }
        }
        this.subjectTriggered = true;
        setTimeout(() => {
          if (token) {
            this.$expireAt.next(token);
            this.subjectTriggered = false;
          }
        }, this._timeToWait);
        return newTokenPayload;
      }
    }
    return null;
  }

  get newToken(): JwtPayload | null {
    return this._newToken == undefined ? null : this._newToken;
  }

  get timeToWait(): number {
    return this._timeToWait / 1000;
  }

  get expireAt(): number {
    return Math.floor(this._expireAt / 1000);
  }
}
