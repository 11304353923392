import {Component, OnInit} from '@angular/core';

import {ApiService} from '../../core/services/api-service/api.service';
import {
  ItemListFilter,
  ItemListItem,
  ItemListMapper,
} from '../../shared/item-list/ItemListItem';
import {
  MultiDeleteAction,
  MultiListAction,
  ListViewAction,
  ItemDeleteAction,
} from '../../shared/models/Actions';
import {ListActionEvent} from '../../shared/models/ListActionEvent';
import {FormatterService} from '../../core/services/formatter-service/formatter.service';
import {MultiListActionEvent} from '../../shared/models/MultiListActionEvent';
import {ItemActionEvent} from '../../shared/models/ItemActionEvent';
import {PagetitleAction} from '../../shared/pagetitle/pagetitle-action';
import {TranslateService} from '@ngx-translate/core';
import {ItemManager} from '../../shared/item-list/ItemManager';
import {Subject, takeUntil, firstValueFrom} from 'rxjs';
import {LogbookDto} from "../../shared/entities/Logbook/LogbookDto";
import {EVENT, EVENT_INFO_TYPE, EVENT_INFO_TYPE_TRANSLATION, EVENT_TYPE} from "../../shared/lookup/event.lookup";
import {LOGBOOK_TRANSLATION_KEY} from "../../shared/lookup/logbook.lookup";
import {LookupMapping} from "../../shared/lookup/lookup";

@Component({
  selector: 'app-logbook',
  templateUrl: './logbook.component.html',
  styleUrls: ['./logbook.component.scss'],
})
export class LogbookComponent implements OnInit, ItemListMapper<LogbookDto> {
  private unsubscribeSubject: Subject<void> = new Subject<void>();
  private _itemManager: ItemManager<LogbookDto>;
  skeletonShowAfterMilliSeconds: number = 200;
  loading: boolean = false;


  selectedItem$: Subject<LogbookDto> = new Subject();
  selectedItem: LogbookDto | undefined;
  itemSelected = false;

  pageActions: PagetitleAction[] = [
    new PagetitleAction(
      'LOGBOOK.PDF.BUTTON',
      'create-pdf',
      'btn-outline-primary',
      'mdi mdi-file-download-outline'
    ),
  ];

  private _logbookDetails: { value: string, title: string }[] | undefined;
  get logbookDetails(): { value: string, title: string }[] | undefined {
    return this._logbookDetails?.filter(detail => detail.value.replaceAll(' ', '').length > 0 && detail.title.replaceAll(' ', '').length > 0);
  }

  multiSelectActions: MultiListAction[] = [];

  searchQuery: string = '';
  itemListFilter = new ItemListFilter<LogbookDto>((item, listItem) => {
    return String(listItem.name)
      .toLowerCase()
      .includes(this.searchQuery.toLowerCase());
  });

  // Search
  typeaheadIndex = new Set<string>();

  get searchIndex(): string[] {
    return [...this.typeaheadIndex.values()];
  }

  logbookTitle: string = '';
  logbookSummary: string = '-';
  timestamp: number = 0;
  canDeleteLogbookEntry: boolean = false;

  private _isLoading: boolean = false;
  private _isLoadingPdf: boolean = false;

  get isLoading():boolean {
    return this._isLoading;
  }
  get isLoadingPdf():boolean {
    return this._isLoadingPdf;
  }

  constructor(
    private apiService: ApiService,
    private formatter: FormatterService,
    private translate: TranslateService,
  ) {
    this._itemManager = new ItemManager<LogbookDto>(this);
  }

  async ngOnInit() {
    this._isLoading = true;
    this.canDeleteLogbookEntry = this.getCanDeleteLogbookEntry();
    this.apiService.logbook.resetRequest();

    this._itemManager.setItems(await this.resolveNext());
    this.skeletonShowAfterMilliSeconds = 0;
    this.updateTypeaheadIndex();

    if (this.canDeleteLogbookEntry) {
      this.multiSelectActions = [new MultiDeleteAction()];
    }
    this._isLoading = false;
  }

  ngAfterViewInit() {
    this.subscribeToSelectedItem();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  subscribeToSelectedItem() {
    this.selectedItem$
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(async (item) => {
        this._logbookDetails = [{
          value: [...EVENT.filter(value => value.id == item.eventTypeId), {
            id: -1,
            value: 'LOGBOOK.DETAILS.DATA_UNKNOWN'
          }].map(value => value.id === -1 ? value.value : `LOGBOOK.EVENTS.${value.value.toUpperCase()}`)[0],
          title: 'LOGBOOK.FIELD.DESCRIPTION.TEXT',
        }];
        firstValueFrom(await this.apiService.logbook.loadDetails(item.uuid)).then(details => {
            details.forEach(detail => {
              let value = detail.value;
              if (detail.technicalType == undefined || detail.technicalType.length == 0) {
                switch (detail.eventInfoTypeId) {
                  case 1001:
                    value = this.formatter.formatTimestamp(parseInt(detail.value));
                }
              } else {
                const tech = EVENT_INFO_TYPE_TRANSLATION(detail.technicalType);
                value = [...tech.lookup.filter(lookup => `${lookup.id}` == detail.value), {
                  id: 0,
                  value: ''
                } as LookupMapping].map(map => {
                  if (map.value.length == 0) {
                    return value;
                  }
                  return `${this.translate.instant(`${tech.translationKey}${map.value.toUpperCase()}`)} ${tech.suffix}`;
                })[0];
              }
              this._logbookDetails?.push({
                value: value,
                title: `LOGBOOK.EVENT_INFO_TYPE.${EVENT_INFO_TYPE.filter(type => type.id == detail.eventInfoTypeId)[0].value.toUpperCase()}`,
              });
            });
          }
        );
        this.logbookSummary = item.logbookSummary;
        this.timestamp = item.timestamp as number;
        this.logbookTitle = [...EVENT_TYPE.filter(value => value.id == item.eventTypeId), {
          id: -1,
          value: 'LOGBOOK.DETAILS.DATA_UNKNOWN'
        }].map(value => value.id === -1 ? value.value : `LOGBOOK.EVENT_TYPE.${value.value.toUpperCase().replaceAll('EVENT_', '')}`)[0];
      });
  }

  private unsubscribe() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  async onVisibility(visible: boolean) {
    if (visible && !this.isLoading && !this.reachedEnd) {
      this._itemManager.setItems(await this.resolveNext());
      this.updateTypeaheadIndex();
    }
  }

  private async resolveNext(): Promise<LogbookDto[]> {
    return (await this.apiService.logbook.loadNext()).map(value => {
      LOGBOOK_TRANSLATION_KEY.forEach(translationKey => {
        if (value.logbookSummary.includes(`${translationKey.name} `)) {
          value.logbookSummary = value.logbookSummary.replaceAll(translationKey.name, this.translate.instant(translationKey.translationKey));
        }
      });
      return value;
    });
  }

  get reachedEnd() {
    return this.apiService.logbook.isCompletelyLoaded;
  }

  getCanDeleteLogbookEntry(): boolean {
    return this.apiService.logbook.canDelete;
  }

  onListEvent(actionEvent: ListActionEvent<LogbookDto>) {
    if (actionEvent == undefined) return;
    if (this.canDeleteLogbookEntry) {
      actionEvent.addItemAction(new ItemDeleteAction().setReturnAction(true));
    }

    this.onSelect(actionEvent.item);
  }

  private onSelect(item: LogbookDto) {
    this.selectedItem = item;
    this.selectedItem$.next(item);
  }

  async onMultiDeleteEvent(actionEvent: MultiListActionEvent<LogbookDto>) {
    if (actionEvent == undefined) return;

    const idsToDelete = actionEvent.items.map<string>((item) => item.uuid);
    await this.onDelete(...idsToDelete);
  }

  async onItemDelete(event: ItemActionEvent<LogbookDto>) {
    await this.onDelete(event.item.uuid);
  }

  async onDelete(...itemIds: string[]) {
    this._itemManager.setItems(
      await this.apiService.logbook.deleteEntries(itemIds)
    );
  }

  mapToItemList(item: LogbookDto): ItemListItem<LogbookDto> {
    const logbookSummary =
      item.logbookSummary?.length > 100
        ? `${item.logbookSummary.substring(0, 100).trim()}...`
        : item.logbookSummary;
    return new ItemListItem(item.uuid,
      this.translate.instant(`LOGBOOK.EVENT_TYPE.${item.title!.toUpperCase()}`), item)
      .addInfo(this.formatter.formatTimestamp(item.timestamp))
      .addInfo(logbookSummary)
      .addAction(new ListViewAction());
  }

  async onCreatePdf() {
    this._isLoadingPdf = true;
    await this.apiService.logbook.createPdfContent(this.translate);
    this._isLoadingPdf = false;
  }

  private updateTypeaheadIndex() {
    this.typeaheadIndex.clear();
    this._itemManager.forEach((value) => {
      this.typeaheadIndex.add(value.name);
    });
  }

  onSearch(search: string) {
    this.searchQuery = search;
    this.itemListFilter.triggerItemUpdate();
  }

  get itemManager(): ItemManager<LogbookDto> {
    let pufferManagerForTranslation = this._itemManager;
    pufferManagerForTranslation.items.forEach((item, key) => {
      pufferManagerForTranslation.items.set(key, item)
    })
    return this._itemManager;
  }
}
