import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {SelectListItem} from "../../../../../shared/select-list/SelectListItem";
import {ApiService} from "../../../../../core/services/api-service/api.service";
import {SelectListComponent} from "../../../../../shared/select-list/select-list.component";
import {DeviceGroupType} from "../../../../../core/enums/deviceGroupType";
import {LockingDeviceGroupDetailsDto} from "../../../../../shared/entities/locking-device/LockingDeviceGroupDetailsDto";
import {
  LockingDeviceGroupAssignmentDto
} from "../../../../../shared/entities/locking-device/LockingDeviceGroupAssignmentDto";
import {
  LockingDeviceGroupAssignableDto
} from "../../../../../shared/entities/locking-device/LockingDeviceGroupAssignableDto";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-locking-device-group-devices',
  templateUrl: './locking-device-group-devices.component.html',
  styleUrls: ['./locking-device-group-devices.component.scss']
})
export class LockingDeviceGroupDevicesComponent {

  @ViewChild(SelectListComponent) selectListComponent!: SelectListComponent

  @Input() readonly: boolean = false;

  @Input() set device(lockingDevice: LockingDeviceGroupDetailsDto | undefined) {
    this.setDevices(lockingDevice);
  }

  setDevices(lockingDeviceGroup: LockingDeviceGroupDetailsDto | undefined) {
    if (lockingDeviceGroup == undefined) {
      lockingDeviceGroup = LockingDeviceGroupDetailsDto.emptyLockingDeviceGroupDetailsDto();
      lockingDeviceGroup.groupTypeId = 0;
    }
    switch (lockingDeviceGroup.groupTypeId) {
      case DeviceGroupType.accessProfileGroup:
        this.apiService.lockingDeviceGroup.getAssignableDevicesForAccess(lockingDeviceGroup.uuid).then(lockingDeviceGroupObservable => {
            firstValueFrom(lockingDeviceGroupObservable).then(
              lockingDeviceGroupDto =>
                this.setAssignableDevices(lockingDeviceGroupDto, (lockingDeviceGroup?.devices || []))
            );
          }
        );
        break;
      case DeviceGroupType.paramGroup:
        this.apiService.lockingDeviceGroup.getAssignableDevicesForParams().then(lockingDeviceGroupObservable => {
            firstValueFrom(lockingDeviceGroupObservable).then(
              lockingDeviceGroupDto =>
                this.setAssignableDevices(lockingDeviceGroupDto, (lockingDeviceGroup!.devices || []))
            );
          }
        );
        break;
      default:
        this.apiService.lockingDevice.getAll().then(
          lockingDeviceObservable => firstValueFrom(lockingDeviceObservable).then(
            lockingDeviceDto =>
              this.setAssignableDevices(lockingDeviceDto.map(value =>
                new LockingDeviceGroupAssignableDto(value.uuid, value.deviceUid, value.shortName, value.description, value.deviceTypeId)
              ), (lockingDeviceGroup?.devices || []))
          )
        );
        break;
    }

  }

  setAssignableDevices(assignableDevices: LockingDeviceGroupAssignableDto[], assignedDevices: LockingDeviceGroupAssignmentDto[]) {
    this.selectedItems = assignedDevices.reduce<Set<string>>((set, value) => {
      set.add(value.uuid)
      return set
    }, new Set<string>());

    assignableDevices.push(...assignedDevices.map(assignmentToAssignable =>
      new LockingDeviceGroupAssignableDto(
        assignmentToAssignable.uuid,
        assignmentToAssignable.uid,
        assignmentToAssignable.shortName,
        undefined,
        assignmentToAssignable.deviceTypeId
      )
    ));

    this.items = assignableDevices.map<SelectListItem>((value) => {
      const listItem = new SelectListItem(value.uuid, value.shortName, value.uid.toString());
      listItem.setImage(`assets/ces/locking-device/${this.apiService.lockingDevice.getImageNameForDeviceType(value.deviceTypeId || 0)}.svg`);
      return listItem;
    });
  }

  @Output() onChanges: EventEmitter<boolean> = new EventEmitter<boolean>()

  items: SelectListItem[] = [];
  selectedItems = new Set<string>();

  private _changed = false

  constructor(private apiService: ApiService) {
  }

  onSelectionChanges(changed: boolean) {
    this._changed = changed;
    this.onChanges.emit(changed);
  }

  getAssignments(): LockingDeviceGroupAssignmentDto[] {
    return [...this.selectListComponent.selectedItems.values()]
      .map<LockingDeviceGroupAssignmentDto>((uuid) => new LockingDeviceGroupAssignmentDto(uuid, -1, uuid, -1)) || [];
  }

  get changed(): boolean {
    return this._changed;
  }

  reset() {
    this.items = [];
    this.selectedItems.clear();
    this.selectListComponent.resetChangeDetection();
  }

}
