import {Injectable} from '@angular/core';

export interface ValidateResult {
  isValid: boolean;
  data: any;
  plain: string;
}

@Injectable({providedIn: 'root'})
export class LocalStorageSchemaService {

  keys = ['lang', 'session-credentials', 'login-data', 'data-node-config'];
  regexPatternLang = new RegExp('^[A-Za-z_.]+$');

  constructor() {
  }

  public localStorage(key: string, remove?: boolean): ValidateResult {
    if (remove == true) {
      window.localStorage.removeItem(key);
      return {isValid: false, data: undefined, plain: ''} as ValidateResult;
    }
    const storage: string | undefined = window.localStorage.getItem(key) || undefined;
    let isValid: boolean = storage != undefined;
    if (isValid) {
      switch (key) {
        case undefined:
          return {isValid: false, data: undefined, plain: ''} as ValidateResult;
        case 'lang':
          isValid = this.regexPatternLang.test(storage!)!;
          return {isValid: isValid, data: storage, plain: storage} as ValidateResult
        case 'session-credentials':
          isValid = storage!.includes('token') && storage!.includes('expireAt') && storage!.includes('changePassword') &&
            storage!.includes('userUUID') && storage!.includes('roleId') && storage!.includes('username');
          break;
        case 'login-data':
          isValid = storage!.includes('system') && storage!.includes('user');
          break;
        case 'data-node-config':
          isValid = storage!.includes('scheme') && storage!.includes('host') && storage!.includes('path') &&
            storage!.includes('apiKey') && storage!.includes('isDataNode');
          break;
        case 'client-data':
          isValid = storage!.includes('licenseExpireTimestamp') && storage!.includes('systemId')
          break;
      }
    }
    let data = undefined;
    if (isValid && this.isJsonString(storage!)) {
      data = JSON.parse(storage!);
    } else if (isValid) {
      const recString = this.recoverHelper(storage!);
      if (recString.length > 0) {
        window.localStorage.setItem(key, recString);
      }
    } else {
      window.localStorage.removeItem(key);
    }
    return {isValid: isValid, data: data, plain: storage} as ValidateResult;
  }

  private isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  private recoverHelper(str: string | undefined): string {
    if (str == undefined || str === '') {
      return '';
    }
    if (str.startsWith("\"") && str.endsWith("\"")) {
      const substring: string = str.substring(0, str.length - 1);
      return substring.replace('\\"', '');
    }
    return str
  }
}
