import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PagetitleComponent} from './pagetitle/pagetitle.component';
import {ItemListComponent} from './item-list/item-list.component';
import {
  NgbActiveModal,
  NgbCollapseModule,
  NgbDropdownModule, NgbInputDatepicker,
  NgbPopoverModule, NgbTimepicker,
  NgbToast, NgbTooltip,
  NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SelectListComponent} from './select-list/select-list.component';
import {CoreModule} from "../core/core.module";
import {CollapseContainerComponent} from './collapse-container/collapse-container.component';
import {PasswordComplexityComponent} from "./password-complexity/password-complexity.component";
import {SkeletonComponent} from "./skeleton/skeleton.component";
import {ToastContainerComponent} from "./notification/toast/toast-container.component";
import {ModalContainerComponent} from "./notification/modal/modal-container.component";
import {DateTimePickerComponent} from "./date-time-picker/date-time-picker.component";

@NgModule({
  providers: [
    NgbActiveModal
  ],
  declarations: [
    PagetitleComponent,
    ItemListComponent,
    SelectListComponent,
    CollapseContainerComponent,
    DateTimePickerComponent,
    PasswordComplexityComponent,
    SkeletonComponent,
    ToastContainerComponent,
    ModalContainerComponent
  ],
  imports: [
    CommonModule,
    NgbCollapseModule,
    NgbDropdownModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgbPopoverModule,
    CoreModule,
    NgbToast,
    FormsModule,
    NgbTimepicker,
    NgbInputDatepicker,
    NgbTooltip
  ],
  exports: [
    PagetitleComponent,
    ItemListComponent,
    SelectListComponent,
    CollapseContainerComponent,
    DateTimePickerComponent,
    PasswordComplexityComponent,
    SkeletonComponent,
    ToastContainerComponent,
    ModalContainerComponent
  ]
})
export class SharedModule {
}
