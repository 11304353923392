import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ImageCroppedEvent, ImageCropperComponent} from "ngx-image-cropper";
import {SessionManager} from "../../../../../core/services/auth-service/support-services/SessionManager";
import {UserRequestDto} from "../../../../../shared/entities/user/UserRequestDto";
import {ROLE} from "../../../../../shared/lookup/role.lookup";
import {COUNTRY} from "../../../../../shared/lookup/country.lookup";
import {LookupMapping} from "../../../../../shared/lookup/lookup";
import {ToastService} from "../../../../../shared/notification/toast/toast.service";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";
import 'hammerjs';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {BlobBase64} from "../../../../../shared/util/blobBase64";
import {ApiService} from "../../../../../core/services/api-service/api.service";
import {parseInt} from "lodash";
import {UserWithImage} from "../../../../../shared/util/change-detector/models/user-with-image";

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
  croppedImage: SafeUrl  = '';

  // Access
  @Input() canChangeUserRole = false
  @Input() canChangeUserAccessSettings = false
  @Input() licenseExpired = false

  // Events
  @Output() onChanges = new EventEmitter<boolean>();

  // Data
  userValue: ChangeDetectorValue = new ChangeDetectorValue(undefined);

  // User image
  userImageData?: string
  userImageBlob?: Blob;
  userImageChanged = false
  private _hasUserImage: boolean = false
  imageTooLarge: boolean = false

  isSelectedUserLoggedIn = false;
  isNewUser = false

  imageModal?: NgbModalRef
  imageFile?: File

  validatorMaxLength10: number = 10;
  validatorMaxLength64: number = 64;

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;

  @Input() set user(user: UserWithImage) {
    if(user == null) return;

    this.isSelectedUserLoggedIn = SessionManager.getInstance().sessionCredentials!.userUUID == user.uuid;
    this.isNewUser = (!user.uuid || user.uuid == "")
    this.userValue = new ChangeDetectorValue(user, () => {this.onChanges.emit()}, user.validators());

    this._hasUserImage = user.imageAvailable || false;
    this.userImageData = user.userImage;
    this.userImageChanged = false;
  }

  constructor(
    private modalService: NgbModal,
    private notification: ToastService,
    private apiService: ApiService
  ) {
  }

  reset() {
    this.userImageData = undefined;
    this.userImageChanged = false;
    this.userValue.reset();
  }

  async onSelectImage(modal: any, event: any) {
    this.imageTooLarge = false
    this.imageFile = event.target?.files?.[0]

    // reset selector to ensure same file is selectable again
    const selectorElement = document.getElementById('userImageSelector') as HTMLInputElement
    selectorElement.files = null
    selectorElement.value = ''

    if(this.imageFile != null) {
      this.imageModal = this.modalService.open(modal, {centered: true, scrollable: false})
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    const maxFileSizeMB = 2; // Maximum file size in megabytes
    this.userImageBlob = event.blob == null ? undefined : event.blob;
    this.imageTooLarge = (this.userImageBlob?.size || 0) > maxFileSizeMB * 1024 * 1024;
  }

  async onSaveImage() {
    const base64Image: string = await BlobBase64.BLOB_TO_BASE_64(this.userImageBlob!);

    if(this.userImageBlob == undefined) {
      this.imageModal?.close()
      this.notification.showError('NOTIFICATION.TOAST.ERROR.DEFAULT');
      return;
    }

    // update user image data
    this.userImageData = base64Image!.substring(base64Image!.indexOf(';base64,') + 8) // get image data only
    this.userImageChanged = true

    this.onChanges.next(true);

    this._hasUserImage = true;
    this.imageModal?.close();
  }

  onClearUserImage() {
    this._hasUserImage = false
    this.userValue.value.imageAvailable = false;
    this.onChanges.next(true);
  }


  getUserRequestDto(): UserRequestDto {
    return this.userValue.value as UserRequestDto;
  }

  getUserImageData(): string {
    return this.userImageData || '';
  }

  getUserImage(): Blob | undefined {
    return this.userImageBlob;
  }

  requireUserImageUpdate(): boolean {
    return this.userImageChanged || this._hasUserImage != (this.userValue.value.imageAvailable || false);
  }

  get isBlackDesign(): boolean {
    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null) {
      return themeDesign.includes('black');
    }
    return false;
  }

  protected readonly document = document;

  get userRoleTranslationText(): string {
    const role: LookupMapping[] = ROLE.filter(value => `${this.userValue.value.roleId}` == `${value.id}`);
    return `USERS.USERS.ROLE.${role.length > 0 ? role[0].value.toUpperCase() : 'UNKNOWN'}`;
  }

  get userCountryTranslationText(): string {
    switch (parseInt(this.userValue.value.countryId || 0)) {
      case 1:
        return 'COUNTRY.GERMANY';
      case 2:
        return 'COUNTRY.NETHERLANDS';
      default:
        return `USERS.USERS.FIELD.COUNTRY.EMPTY`;
    }
  }

  protected readonly ROLE = ROLE.filter(role => role.id != 1);
  protected readonly COUNTRY = COUNTRY;

  get userIsAllowedToResetPassword(): boolean {
    return SessionManager.getInstance().isAdminOrHigher;
  }

  async resetPassword() {
    const result = await this.apiService.auth.resetPassword(
      SessionManager.getInstance().getSystemData()!.systemId, this.userValue.value.username);
  }

  get hasUserImage(): boolean {
    return this._hasUserImage;
  }

  get defaultImage(): string {
    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null && themeDesign.includes('black')) {
      return "assets/ces/user/user-black.svg";
    }
    return "assets/ces/user/user.svg";
  }
}
