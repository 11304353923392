import {Component, OnInit} from '@angular/core';
import {IModalContent} from "../IModalContent";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SubmitUtils} from "../../../../shared/util/SubmitUtils";
import {AuthenticationService} from "../../../../core/services/auth-service/auth.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PasswordComplexityComponent} from "../../../../shared/password-complexity/password-complexity.component";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, IModalContent {

  changePasswordForm: FormGroup;
  loading: boolean = false;

  constructor(
    private notification: ToastService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private ngbModal: NgbActiveModal) {
    this.changePasswordForm = new FormGroup({});
  }

  async ngOnInit(): Promise<void> {
    const passwordComplexityRegex: string = await new PasswordComplexityComponent(this.apiService).complexityRegexPattern();

    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl('', [
        Validators.required,
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(passwordComplexityRegex),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
      ]),
    });
  }

  get f() {
    return this.changePasswordForm!!.controls;
  }

  async onSubmit() {
    if (SubmitUtils.checkControls(this.notification, true, this.changePasswordForm!)) {
      return;
    }
    this.loading = true;

    this.authService.changePassword(
      this.changePasswordForm!.get('oldPassword')?.value,
      this.changePasswordForm!.get('newPassword')?.value,
      this.changePasswordForm!.get('confirmPassword')?.value)
      .then(() => {
        this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.UPDATE');
        this.onClose();
      })
      .finally(() => this.loading = false);
  }

  onClose() {
    this.ngbModal.close()
  }
}
