import {AfterViewInit, Component} from '@angular/core';
import {LanguageService} from './core/services/language-service/language.service';
import {SessionManager} from "./core/services/auth-service/support-services/SessionManager";
import {ApiService} from "./core/services/api-service/api.service";
import {ModalContainerContent} from "./shared/notification/modal/modal-container.class";
import {ModalService} from "./shared/notification/modal/modal.service";
import {Router} from "@angular/router";
import {RefreshTokenHelper} from "./shared/util/RefreshTokenHelper";
import {UserSessionInfoDto} from "./shared/entities/user/UserSessionInfoDto";
import {$notifyRelogin} from "./shared/util/RefreshTokenFallbackNotifier";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'adminAngular';
  defaultLang = 'de';

  private modalOpened: boolean = false;

  // Reference languageService to be initialized
  constructor(
    language: LanguageService,
    private apiService: ApiService,
    private modalService: ModalService,
    private router: Router,
  ) {
    language.setDefaultLanguage(this.defaultLang);
  }

  ngAfterViewInit(): void {
    this.apiService.auth.refreshTokenHelper.$expireAt.subscribe(() =>
      this.apiService.auth.refreshToken(true).then(response => {
        if (!response.headers.has('New-Token')) {
          this.reLogin();
          return;
        }
        const helper: RefreshTokenHelper = RefreshTokenHelper.getInstance(response.headers.get('New-Token'));
        const session = SessionManager.getInstance().sessionCredentials!;
        SessionManager.getInstance().setSessionCredentials(new UserSessionInfoDto(
          helper.token!,
          helper.expireAt,
          session.changePassword,
          session.userUUID,
          session.roleId,
          'webclient',
          session.username,
          session.hasProfileImage,
          session.systemIdentifier,
          session.onlineAvailable,
          'refresh'
        ));
      })
    );

    if (!this.apiService.auth.refreshTokenHelper.subjectTriggered) {
      this.apiService.auth.refreshTokenHelper.redeclareToken(SessionManager.getInstance().sessionCredentials?.token);
    }

    $notifyRelogin.subscribe(relogin => {
      if (relogin && !this.modalOpened) {
        this.reLogin();
      }
    })
  }

  private reLogin(): void {
    if (this.modalOpened || this.apiService.auth.isValidOrTryRestoreSession() || SessionManager.getInstance().sessionCredentials == undefined) {
      return;
    }
    this.modalOpened = true;
    let modal: ModalContainerContent = new ModalContainerContent(
      'NOTIFICATION.ALERT.SESSION.TITLE',
      '',
      undefined,
      undefined,
      'NOTIFICATION.ALERT.BUTTON.LOGIN',
      'NOTIFICATION.ALERT.BUTTON.LOGOUT',
      'btn-outline-light',
      'btn-outline-danger',
      'refreshToken');

    this.modalService.openModal(modal).then(async result => {
      if (typeof result === 'string') {
        if (!await this.apiService.auth.relogin(result as string)) {
          this.modalOpened = false;
          this.reLogin();
          return;
        }
      } else {
        this.apiService.auth.logout().finally(() => this.router.navigate(['/user/login']).catch());
      }
    }).finally(() => {
      this.modalOpened = false;
    });
  }

}
