import {AccessProfile} from "./AccessProfile";
import {AccessProfileDetailTimeModelDto} from "./AccessProfileDetailTimeModelDto";
import {AccessProfileDetailLockingDeviceDto} from "./AccessProfileDetailLockingDeviceDto";
import {AccessProfileDetailLockingDeviceGroupDto} from "./AccessProfileDetailLockingDeviceGroupDto";

export class AccessProfileDetailDto extends AccessProfile {
  timeModels: AccessProfileDetailTimeModelDto[] | undefined;
  lockingDevices: AccessProfileDetailLockingDeviceDto[] | undefined;
  lockingDeviceGroups: AccessProfileDetailLockingDeviceGroupDto[] | undefined;

  constructor(uuid: string,
              name: string,
              description: string | undefined,
              accessTypeId: number,
              timeModels: AccessProfileDetailTimeModelDto[] | undefined,
              lockingDevices: AccessProfileDetailLockingDeviceDto[] | undefined,
              lockingDeviceGroups: AccessProfileDetailLockingDeviceGroupDto[] | undefined,
              officeFunction: boolean,
              pin: boolean,) {
    super(uuid, name, description, accessTypeId, officeFunction, pin);
    this.timeModels = timeModels;
    this.lockingDevices = lockingDevices;
    this.lockingDeviceGroups = lockingDeviceGroups;
  }
}
