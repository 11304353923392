import {Component, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {ITimeModelDynamicForm} from "../ITimeModelDynamicForm";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {Trigger} from "../../../../shared/util/Trigger";
import {TimeModelDto} from "../../../../shared/entities/TimeModel/TimeModelDto";
import {TimeModelExecutionRepetitionDto} from "../../../../shared/entities/TimeModel/TimeModelExecutionRepetitionDto";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

const validTypes = [8, 9]
const secondsPerDay = 86400

interface RecurringTimeModel {
  executionTypeId: number,
  executionValue: number,
  date: { year: number, month: number, day: number },
  id: number,
  uuid: string
}

@Component({
  selector: 'app-time-model-list-of-days-form',
  templateUrl: './time-model-list-of-days-form.component.html',
  styleUrls: ['./time-model-list-of-days-form.component.scss'],
  animations: [Trigger.FadeAnimation]
})
export class TimeModelListOfDaysFormComponent implements OnInit, ITimeModelDynamicForm {
  startDate: { year: number, month: number, day: number } =
    {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};

  private _days: ChangeDetectorValue = new ChangeDetectorValue([{
    executionTypeId: 0,
    executionValue: 0,
    date: {year: 0, day: 0, month: 0},
    id: 0,
    uuid: '',
  } as RecurringTimeModel], () => {
    this.onChanges()
  });

  get daysValue() {
    return (this.days.value as RecurringTimeModel[]).sort((a, b) =>
      this.formatter.formatFromNgbDatePicker(a.date).getTime() -
      this.formatter.formatFromNgbDatePicker(b.date).getTime());
  }

  accessReadonly: boolean = false;

  readonly minSelectableDate = new Date("2020-01-01T00:00:00.000")

  readonly customFormEnabled = true;
  readonly intervalEnabled = false;

  get dynamicForm(): AbstractControl | boolean | null {
    //is valid
    return !this.invalid;
  }

  constructor(
    private formatter: FormatterService) {
  }

  ngOnInit(): void {
  }

  initialize(timeModel: TimeModelDto, accessReadonly: boolean): void {

    this.accessReadonly = accessReadonly

    // Update executionRepetition
    this.days.value = [];
    let allDays: RecurringTimeModel[] = [] as RecurringTimeModel[];

    ((timeModel.executionRepetition || []) as TimeModelExecutionRepetitionDto[]).forEach((execution) => {
      if (validTypes.includes(execution.executionTypeId!) && execution.executionValue! % secondsPerDay == 0) {
        const date = new Date(this.formatter.getUTCMilliseconds(execution.executionValue!));
        allDays.push({
          executionTypeId: execution.executionTypeId,
          executionValue: execution.executionValue,
          date: {
            year: date.getFullYear() == 1970 ? new Date().getFullYear() : date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
          },
          uuid: execution.uuid,
          id: execution.id
        } as RecurringTimeModel);
      }
    });
    this.days.value = allDays;
    this.days.reset();
  }

  changeRecurring(d: any) {
    let changedValues = ([] as RecurringTimeModel[]);
    (this.days.value as RecurringTimeModel[]).forEach(value => {
      changedValues.push({
        executionTypeId: (JSON.stringify(value.date) === JSON.stringify(d.date) ?
          (value.executionTypeId == 9 ? 8: 9) : (value.executionTypeId)),
        executionValue: value.executionValue,
        date: value.date,
        uuid: value.uuid,
        id: value.id
      });
    });
    this.days.value = changedValues;
  }

  isRecurring(executionTypeId: number): boolean {
    return executionTypeId == 9;
  }

  getTimeModel(): TimeModelDto {
    let timeModel: TimeModelDto = TimeModelDto.emptyTimeModelDto();

    timeModel.executionRepetition = (this.days.value as RecurringTimeModel[]).map((value, index) => {
      value.executionValue = this.formatter.formatFromNgbDatePicker(value.date).getTime() / 1000;
      if (value.executionTypeId == 9) {
        value.executionValue = this.formatter.formatFromNgbDatePicker({
          day: value.date.day,
          month: value.date.month,
          year: 1970
        }).getTime() / 1000;
      }
      return new TimeModelExecutionRepetitionDto(
        value.uuid,
        value.id < 0 ? -index - 1 : value.id,
        value.executionTypeId,
        undefined,
        value.executionValue
      );
    });
    timeModel.intervals = [];

    return timeModel;
  }

  get invalid(): boolean {
    return this.hasDuplicatedDays || (this.days.value as RecurringTimeModel[]).length > 100;
  }

  onChanges(): void {
  }

  get changed(): boolean {
    return this.days.hasChanges;
  }

  addDay() {
    if (!this.isDayAlreadyAdded) {
      let newDays = this.days.value;
      newDays.push({
        executionTypeId: 8,
        executionValue: this.formatter.formatFromNgbDatePicker(this.startDate).getTime() / 1000,
        date: this.startDate,
        id: 0,
        uuid: '',
      } as RecurringTimeModel);
      this.days.value = newDays;
    }
  }

  removeDay(d: any) {
    if (this.deleteEnabled) {
      this.days.value = (this.days.value as RecurringTimeModel[]).filter((value) => JSON.stringify(value.date) != JSON.stringify(d.date));
    }
  }

  get daysLength(): number {
    return (this.days.value as RecurringTimeModel[]).length;
  }

  get addEnabled() {
    return this.daysLength < 100 && !this.isDayAlreadyAdded;
  }

  get deleteEnabled() {
    return this.daysLength > 1;
  }

  get isDayAlreadyAdded(): boolean {
    return (this.days.value as RecurringTimeModel[]).filter(value =>
      JSON.stringify(value.date) == JSON.stringify(this.startDate)
    ).length != 0;
  }

  get hasDuplicatedDays(): boolean {
    let foundDuplicates: boolean = false;
    (this.days.value as RecurringTimeModel[]).forEach((day) => {
      if ((this.days.value as RecurringTimeModel[]).filter(value =>
        JSON.stringify(value.date) == JSON.stringify(day.date)
      ).length > 1){
        foundDuplicates = true;
      }
    });
    return foundDuplicates;
  }

  get days(): ChangeDetectorValue {
    return this._days;
  }
}
