<form [formGroup]="lockingDeviceForm">
  <div class="row row-cols-lg-6 g-4">

    <div class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "LOCKING_DEVICES.DEVICES.SECTION.GENERAL" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="shortName"
                   formControlName="shortName"
                   [ngClass]="{ 'is-invalid': f['shortName'] | hasErrors }">
            <label for="shortName">{{ "LOCKING_DEVICES.DEVICES.FIELD.SHORT_NAME.TEXT" | translate }}*</label>
            <div class="form-floating-icon">
              <i icon="name"></i>
            </div>
            <div *ngIf="f['shortName'] | hasErrors" class="invalid-feedback">
              <div
                *ngIf="f['shortName'] | hasErrors:'required'">{{ "LOCKING_DEVICES.DEVICES.FIELD.SHORT_NAME.REQUIRED" | translate }}
              </div>
              <div
                *ngIf="f['shortName'] | hasErrors:'maxlength'">{{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("LOCKING_DEVICES.DEVICES.FIELD.SHORT_NAME.TEXT" | translate) : ('' + validatorMaxLength64) }}
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="description"
                   formControlName="description">
            <label for="description">{{ "LOCKING_DEVICES.DEVICES.FIELD.DESCRIPTION.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="description"></i>
            </div>
            <div *ngIf="f['description'] | hasErrors" class="invalid-feedback">
              <div
                *ngIf="f['description'] | hasErrors:'maxlength'">{{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("LOCKING_DEVICES.DEVICES.FIELD.DESCRIPTION.TEXT" | translate) : ('' + validatorMaxLength64) }}
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input readonly type="text" class="form-control" id="uid" formControlName="uid">
            <label for="uid">{{ "LOCKING_DEVICES.DEVICES.FIELD.UID.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="id"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input readonly type="text" class="form-control" id="articleNumber" formControlName="articleNumber">
            <label for="articleNumber">{{ "LOCKING_DEVICES.DEVICES.FIELD.ARTICLE_NUMBER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="reference"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "LOCKING_DEVICES.DEVICES.SECTION.INFORMATION" | translate }}</h6>
        <div class="card-body pb-0">

          <div *ngIf="showBatteryIcon" class="form-floating form-floating-custom mb-3">
            <input readonly type="text" class="form-control" id="battery" formControlName="battery">
            <label for="battery">{{ "LOCKING_DEVICES.DEVICES.FIELD.BATTERY.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <img [src]="batteryImage" alt="{{ 'LOCKING_DEVICES.DEVICES.FIELD.BATTERY.TEXT' | translate }}"
                   style="width: 1.3em">
            </div>
          </div>

          <div class="form-floating form-floating-custom flex-grow-1 text-truncate mb-3">
            <input readonly type="text" class="form-control" id="serialNumber" formControlName="serialNumber">
            <label for="serialNumber">{{ "LOCKING_DEVICES.DEVICES.FIELD.SERIAL_NUMBER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="technical"></i>
            </div>
          </div>

          <div class="d-flex gap-1 mb-3">
            <div class="form-floating form-floating-custom flex-grow-1 text-truncate">
              <input readonly type="text" class="form-control" id="firmwareVersion" formControlName="firmwareVersion">
              <label
                for="firmwareVersion">{{ "LOCKING_DEVICES.DEVICES.FIELD.FIRMWARE_VERSION.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="version"></i>
              </div>
            </div>

            <div *ngIf="showHardwareVersion" class="form-floating flex-grow-1 text-truncate">
              <input readonly type="text" class="form-control" id="hardwareVersion" formControlName="hardwareVersion">
              <label
                for="hardwareVersion">{{ "LOCKING_DEVICES.DEVICES.FIELD.HARDWARE_VERSION.TEXT" | translate }}</label>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input readonly type="text" class="form-control" id="technicalId" formControlName="technicalId">
            <label for="technicalId">{{ "LOCKING_DEVICES.DEVICES.FIELD.TECHNICAL_ID.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="technical"></i>
            </div>
          </div>

          <ng-container *ngIf="showLength">
            <div class="d-flex gap-1 mb-3">
              <div class="form-floating form-floating-custom flex-grow-1 text-truncate">
                <input readonly type="text" class="form-control" id="lengthInner" formControlName="lengthInner">
                <label for="lengthInner">{{ "LOCKING_DEVICES.DEVICES.FIELD.LENGTH_INNER.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="distance"></i>
                </div>
              </div>

              <div class="form-floating flex-grow-1 text-truncate">
                <input readonly type="text" class="form-control" id="lengthOuter" formControlName="lengthOuter">
                <label for="lengthOuter">{{ "LOCKING_DEVICES.DEVICES.FIELD.LENGTH_OUTER.TEXT" | translate }}</label>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="showHandleInfo">
            <div class="form-floating form-floating-custom flex-grow-1 text-truncate mb-3">
              <input readonly type="text" class="form-control" id="handleDirection"
                     [value]="(f['handleDirectionId'].value == '1' ? 'LOCKING_DEVICES.DEVICES.HANDLE.LEFT' : 'LOCKING_DEVICES.DEVICES.HANDLE.RIGHT') | translate">
              <label
                for="handleDirection">{{ "LOCKING_DEVICES.DEVICES.FIELD.HANDLE_DIRECTION.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="direction"></i>
              </div>
            </div>

            <div class="d-flex gap-1 mb-3">
              <div class="form-floating form-floating-custom flex-grow-1 text-truncate">
                <input readonly type="text" class="form-control" id="handleInnerType"
                       [value]="('LOCKING_DEVICES.DEVICES.HANDLE.INNER_TYPE_' + f['handleInnerTypeId'].value.toUpperCase()) | translate">
                <label
                  for="handleInnerType">{{ "LOCKING_DEVICES.DEVICES.FIELD.HANDLE_INNER_TYPE.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="info"></i>
                </div>
              </div>

              <div class="form-floating flex-grow-1 text-truncate">
                <input readonly type="text" class="form-control" id="handleOuterType"
                       [value]="('LOCKING_DEVICES.DEVICES.HANDLE.OUTER_TYPE_' + f['handleOuterTypeId'].value.toUpperCase()) | translate">
                <label
                  for="handleOuterType">{{ "LOCKING_DEVICES.DEVICES.FIELD.HANDLE_OUTER_TYPE.TEXT" | translate }}</label>
              </div>
            </div>

            <div class="d-flex gap-1 mb-3">
              <div class="form-floating form-floating-custom flex-grow-1 text-truncate">
                <input readonly type="text" class="form-control" id="cylinderDistance"
                       formControlName="cylinderDistance">
                <label
                  for="cylinderDistance">{{ "LOCKING_DEVICES.DEVICES.FIELD.CYLINDER_DISTANCE.TEXT" | translate }}</label>
                <div class="form-floating-icon">
                  <i icon="distance"></i>
                </div>
              </div>

              <div class="form-floating flex-grow-1 text-truncate">
                <input readonly type="text" class="form-control" id="squareSize" formControlName="squareSize">
                <label for="squareSize">{{ "LOCKING_DEVICES.DEVICES.FIELD.SQUARE_SIZE.TEXT" | translate }}</label>
              </div>
            </div>
          </ng-container>

          <div class="form-floating form-floating-custom">
            <select *ngIf="!accessReadonly; else assemblyState" class="form-select" id="assemblyState"
                    formControlName="assemblyState">
              <option *ngFor="let state of ASSEMBLY_STATE"
                      [value]="state.id">{{ ('LOCKING_DEVICES.DEVICES.ASSEMBLY_STATE.' + state.value.toUpperCase()) | translate }}
              </option>
            </select>
            <ng-template #assemblyState>
              <input readonly id="assemblyState" class="form-control"
                     [value]="assemblyStateTranslationText | translate">
            </ng-template>
            <label for="assemblyState">{{ "LOCKING_DEVICES.DEVICES.FIELD.ASSEMBLY_STATE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="mechanical"></i>
            </div>
          </div>

          <div *ngIf="f['assemblyState']?.value != 1" class="form-floating form-floating-custom mt-3">
            <date-time-picker style="flex-grow: 1"
                              [readonly]="accessReadonly"
                              [(dateValue)]="assemblyDate.value"
                              [showChanges]="assemblyDate.hasChanges">
            </date-time-picker>
          </div>

        </div>
      </div>
    </div>

    <div class="flex-grow-1 h-100">
      <div class="card flex-grow-1 shadow-none">
        <h6 class="card-header">{{ "LOCKING_DEVICES.DEVICES.SECTION.LOCATION" | translate }}</h6>
        <div class="card-body d-flex flex-row flex-wrap gap-3">

          <div class="form-floating form-floating-custom flex-grow-1">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="building"
                   formControlName="building">
            <label for="building">{{ "LOCKING_DEVICES.DEVICES.FIELD.BUILDING.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="building"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom flex-grow-1">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="level" formControlName="level">
            <label for="level">{{ "LOCKING_DEVICES.DEVICES.FIELD.LEVEL.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="building"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom flex-grow-1">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="room" formControlName="room">
            <label for="room">{{ "LOCKING_DEVICES.DEVICES.FIELD.ROOM.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="building"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom flex-grow-1">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="door" formControlName="door">
            <label for="door">{{ "LOCKING_DEVICES.DEVICES.FIELD.DOOR.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="building"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form><!-- end form -->
