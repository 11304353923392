import {NodeManager} from "./NodeManager";
import {HttpClient} from "@angular/common/http";
import {LocalStorageSchemaService, ValidateResult} from "../../json-schema-service/local-storage-schema.service";
import {UserRoleType} from "../../../enums/userRoleType";
import {firstValueFrom, Subject} from "rxjs";
import {ClientDto} from "../../../../shared/entities/client/ClientDto";
import {UserSessionInfoDto} from "../../../../shared/entities/user/UserSessionInfoDto";
import {Injectable} from "@angular/core";
import {RefreshTokenHelper} from "../../../../shared/util/RefreshTokenHelper";

const sessionKey: string = "session-credentials";
const clientKey: string = "client-data";

@Injectable({
  providedIn: 'root'
})
export class SessionManager {
  private readonly fiveMinutes: number = 300;
  private _systemData: ClientDto | undefined;
  private _systemDataSubject: Subject<ClientDto | undefined> = new Subject<ClientDto | undefined>;
  private _sessionDataSubject: Subject<UserSessionInfoDto | undefined> = new Subject<UserSessionInfoDto | undefined>();
  private sessionData?: UserSessionInfoDto;
  private _sessionLanguageSubject: Subject<string> = new Subject<string>();
  private _forcedRefreshToken: Subject<boolean> = new Subject<boolean>();
  private _sessionLanguage: string;
  private _userImageSubject: Subject<string | undefined> = new Subject<string| undefined>();
  private _userImage: string | undefined;

  private static instance: SessionManager;
  private currentLookupVersion: number;

  static getInstance(): SessionManager {
    if (this.instance == undefined) {
      this.instance = new SessionManager(NodeManager.getInstance());
    }
    return this.instance;
  }

  private constructor(private nodeManager: NodeManager) {
    this.nodeManager.retrieveConfig(this.isValidSession());
    this.currentLookupVersion = 0;
    this._sessionLanguage = new LocalStorageSchemaService().localStorage('lang').plain;
  }

  clearCredentials() {
    this.sessionData = undefined;
    const localStorage: LocalStorageSchemaService = new LocalStorageSchemaService();
    localStorage.localStorage('lang').plain;
    localStorage.localStorage(sessionKey, true);
    this.nodeManager.retrieveConfig(this.isValidSession());
    this._sessionDataSubject.next(undefined);
  }

  get sessionCredentials() {
    return this.sessionData;
  }

  get isAdminOrHigher(): boolean {
    switch (this.sessionData?.roleId || UserRoleType.UNKNOWN) {
      case UserRoleType.PTI:
      case UserRoleType.OWNER:
      case UserRoleType.ADMIN:
        return true;
      default:
        return false;
    }
  }

  get isLicenseExpired(): boolean {
    if (this.getSystemData() == undefined || this.getSystemData()!.licenseExpireTimestamp == undefined) {
      return true;
    }
    const dateFromSecondsToMillis: number = this.getSystemData()!.licenseExpireTimestamp! * 1000;
    return new Date(dateFromSecondsToMillis) < new Date();
  }

  get isLicenseBusiness(): boolean {
    if (this.getSystemData() == undefined || this.getSystemData()!.licenseExpireTimestamp == undefined) {
      return false;
    }
    return (this.getSystemData()?.licenseTypeId || 1) > 1;
  }

  setNewLanguage(newCredentials: UserSessionInfoDto | string | undefined, language: string) {
    this.sessionLanguage = language;
    // Currently we need to set credentials because of lookups
    this.setSessionCredentials(newCredentials);
    this._sessionLanguageSubject.next(language);
  }

  setSessionCredentials(newCredentials: UserSessionInfoDto | string | undefined) {
    switch (typeof newCredentials) {
      case "string":
        this.sessionData = JSON.parse(newCredentials);
        break;
      case "object":
        this.sessionData = newCredentials;
        break;
      case "undefined":
      default:
        this.clearCredentials();
        return;
    }
    window.localStorage.setItem(sessionKey, JSON.stringify(this.sessionData));
    this._sessionDataSubject.next(this.sessionData);
  }

  isValidSession(): boolean {
    if (this.sessionData == undefined || this.sessionData.token == null || this.sessionData.expireAt == null) {
      const localSessionCredentials: ValidateResult = new LocalStorageSchemaService().localStorage(sessionKey);
      if (localSessionCredentials.isValid) {
        this.setSessionCredentials(localSessionCredentials.data);
        if (new Date().getTime() / 1000 < localSessionCredentials.data.expireAt) {
          return true;
        }
      }
      return false;
    }
    return (Math.floor(Date.now() / 1000) < (this.sessionData?.expireAt || 0));
  }

  async forceNewToken(httpClient: HttpClient) {
    const response = await firstValueFrom(httpClient.get<any>(this.nodeManager.getDataNodeUrl('auth/refreshToken?force'), {observe: 'response'}));
    let currentSessionCredentials: UserSessionInfoDto = this.sessionCredentials!;
    const helper: RefreshTokenHelper = RefreshTokenHelper.getInstance(response?.headers.get('New-Token'));
    currentSessionCredentials.token = helper.token!;
    currentSessionCredentials.expireAt = helper.expireAt;
    this._forcedRefreshToken.next(true);
    this.setSessionCredentials(currentSessionCredentials);
  }

  // Getter & Setter

  getAndIncrementLookupVersion(): number {
    this.currentLookupVersion = this.currentLookupVersion + 1;
    return this.currentLookupVersion - 1;
  }

  get sessionLanguageSubject(): Subject<string> {
    return this._sessionLanguageSubject;
  }

  get sessionDataSubject(): Subject<UserSessionInfoDto | undefined> {
    return this._sessionDataSubject;
  }

  get sessionLanguage(): string {
    return this._sessionLanguage;
  }

  set sessionLanguage(value: string) {
    this._sessionLanguage = value;
  }

  getSystemData(): ClientDto | undefined {
    if (this._systemData == undefined) {
      this._systemData = new LocalStorageSchemaService().localStorage(clientKey).data as ClientDto;
    }
    return this._systemData;
  }

  setSystemData(value: ClientDto | undefined) {
    this._systemData = value;
    this._systemDataSubject.next(value);
    window.localStorage.setItem(clientKey, JSON.stringify(this._systemData));
  }

  get systemDataSubject(): Subject<ClientDto | undefined> {
    return this._systemDataSubject;
  }

  get userImageSubject(): Subject<string | undefined> {
    return this._userImageSubject;
  }

  get userImage(): string | undefined {
    return this._userImage;
  }

  set userImage(value: string | undefined) {
    this._userImage = value;
    this._userImageSubject.next(value);
  }

  get forcedRefreshToken(): Subject<boolean> {
    return this._forcedRefreshToken;
  }
}
