export class SelectListItem {

  image?: string
  public info: string[]

  constructor(public id: string, public name: string, ...info: string[]) {
    this.info = info
  }

  setImage(image: string): SelectListItem {
    this.image = image
    return this
  }

  addInfo(info: string): SelectListItem {
    this.info.push(info)
    return this
  }

}
