import {Component, OnInit} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {ItemDeleteAction, ItemSaveAction, ListEditAction, ListViewAction} from "../../shared/models/Actions";
import {ListActionEvent} from "../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../shared/models/ItemActionEvent";
import {ChangeDetectorForm} from "../../shared/util/change-detector/ChangeDetectorForm";
import {ItemListFilter, ItemListItem, ItemListMapper} from "../../shared/item-list/ItemListItem";
import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {FormValidator} from "../../shared/util/FormValidator";
import {FormatterService} from "../../core/services/formatter-service/formatter.service";
import {ItemManager} from "../../shared/item-list/ItemManager";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {take} from "rxjs";
import {LockingMediaDto} from "../../shared/entities/LockingMediaDto";
import {MEDIA_IMPLEMENTATION, MEDIA_STATE, MEDIA_TYPE} from "../../shared/lookup/media.lookup";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {ChangeDetectorValue} from "../../shared/util/change-detector/ChangeDetectorValue";

@Component({
  selector: 'app-locking-media',
  templateUrl: './locking-media.component.html',
  styleUrls: ['./locking-media.component.scss'],
})
export class LockingMediaComponent implements OnInit, ItemListMapper<LockingMediaDto>, PendingChangesBlocker {
  handoutDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});
  returnDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});

  _isLoading: boolean = false;
  get isLoading():boolean {
    return this._isLoading;
  }
  itemManager: ItemManager<LockingMediaDto>;
  selectedItem?: LockingMediaDto;
  itemSelected = false;

  saveAction = new ItemSaveAction();
  deleteAction = new ItemDeleteAction();

  licenseTypeId: number = 1;
  licenseIsValidForBusiness: boolean = true;

  accessReadonly = true;

  getAccessReadonly(): boolean {
    return this.apiService.lockingMedia.isReadonly() || (!this.licenseIsValidForBusiness && this.licenseTypeId > 1);
  }

  lockingMediaForm!: FormGroup;
  changeDetector!: ChangeDetectorForm;
  showArticleNumber = false;

  typeaheadIndex = new Set<string>();

  get searchEntries() {
    return [...this.typeaheadIndex.values()];
  }

  searchQuery: string = '';
  searchFilter = new ItemListFilter<LockingMediaDto>((item) => {
    return String(item.displayUid || '')
      .toLowerCase()
      .includes(this.searchQuery.toLowerCase());
  });


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private translate: TranslateService,
    private formatter: FormatterService,
    private notification: ToastService
  ) {
    this.itemManager = new ItemManager<LockingMediaDto>(this, notification);
  }

  async ngOnInit() {
    this._isLoading = true;
    this.accessReadonly = this.getAccessReadonly();
    //Validation Set
    this.lockingMediaForm = this.formBuilder.group(
      {
        uuid: ['', []],
        mediaNumber: ['', []],
        displayUid: ['', []],
        mechanicalId: ['', []],
        articleNumber: ['', []],
        lockingMediaType: ['', []],
        implementationType: ['', []],
        assignedUsers: ['', []],
        state: [0, [Validators.required]],
      }
    );

    this.changeDetector = new ChangeDetectorForm(
      this.lockingMediaForm,
      (changed) => {
        this.saveAction.disabled = !changed;
      }
    );

    this.apiService.lockingMedia.getAll().then(observer => {
      observer.pipe(take(1)).subscribe({
        next: items => {
          this.itemManager.setItems(items);
          this.itemManager.forEach((value) => {
            this.typeaheadIndex.add(value.item.displayUid);
          });
          this._isLoading = false;
        },
        error: () => {
          this._isLoading = false;
        }
      });
    });

    this.licenseTypeId = SessionManager.getInstance().isLicenseBusiness ? 2 : 1;
    this.licenseIsValidForBusiness = this.licenseTypeId == 2 && !SessionManager.getInstance().isLicenseExpired;
  }

  async onSelectEvent(actionEvent: ListActionEvent<LockingMediaDto>) {
    if (actionEvent == undefined) return;

    await this.onSelect(actionEvent.item);

    if (actionEvent.action instanceof ListEditAction) {
      if (this.apiService.lockingMedia.canDeleteLockingMedia(actionEvent.item)) {
        actionEvent.addItemAction(this.deleteAction);
      }

      this.saveAction.disabled = true;
      actionEvent.addItemAction(this.saveAction);
    }
  }

  private async onSelect(item: LockingMediaDto) {
    this.selectedItem = item;

    this.lockingMediaForm.patchValue({
      implementationType: this.translate.instant(`LOCKING_MEDIA.IMPLEMENTATION.${MEDIA_IMPLEMENTATION.filter(value => value.id == item.implementationId)[0].value.toUpperCase()}`),
      lockingMediaType: this.translate.instant(`LOCKING_MEDIA.TYPE.${MEDIA_TYPE.filter(value => value.id == item.typeId)[0].value.toUpperCase()}`),
      uuid: item.uuid,
      mediaNumber: item.mediaNumber,
      displayUid: item.displayUid,
      mechanicalId: item.mechanicalId,
      articleNumber: item.articleNumber,
      assignedUsers: item.assignedUsers,
      state: `${item.stateId}`,
    });
    this.handoutDate = new ChangeDetectorValue(item.dateHandout == null ? null : this.formatter.formatToNgbDatePicker(item.dateHandout));
    this.returnDate = new ChangeDetectorValue(item.dateReturn == null ? null : this.formatter.formatToNgbDatePicker(item.dateReturn));

    this.showArticleNumber = this.apiService.lockingMedia.getIsArticleNumberVisible(item);

    this.changeDetector.refresh();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.lockingMediaForm.controls;
  }

  mapToItemList(item: LockingMediaDto): ItemListItem<LockingMediaDto> {
    return new ItemListItem(item.uuid, item.displayUid, item) //TODO display mechanical ID since UID won't be displayed on self scanned media?
      .addInfo(this.formatter.formatArray(item.assignedUsers || [], this.translate.instant('LOCKING_MEDIA.ITEM_LIST.NO_USERS')))
      .setImage(`assets/ces/locking-media/${this.apiService.lockingMedia.getImageNameForMediaType(item.implementationId)}.svg`)
      .addAction(this.accessReadonly ? new ListViewAction() : new ListEditAction());
  }

  hasPendingChanges(): boolean {
    return this.changeDetector.changed || this.returnDate.hasChanges || this.handoutDate.hasChanges;
  }

  async onEditEvent(actionEvent: ItemActionEvent<LockingMediaDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList);
    }
    if (actionEvent.action instanceof ItemDeleteAction) {
      await this.onDelete(actionEvent.returnToList);
    }
  }

  resetForms() {
    this.lockingMediaForm.reset();
    this.lockingMediaForm.markAsUntouched();
    this.changeDetector.refresh();
    this.handoutDate.reset();
    this.returnDate.reset();
  }

  async onSubmit(returnToList: () => void) {
    // stop here if form is invalid
    if (SubmitUtils.checkControls(this.notification, true, this.lockingMediaForm)) {
      return;
    }

    // update values
    this.selectedItem!.mechanicalId = this.lockingMediaForm.get('mechanicalId')?.value;
    this.selectedItem!.stateId = parseInt(this.lockingMediaForm.get('state')?.value)!;
    this.selectedItem!.dateHandout = this.handoutDate.value == null ? undefined : this.formatter.formatFromNgbDatePicker(this.handoutDate.value).getTime() / 1000;
    this.selectedItem!.dateReturn = this.returnDate.value == null ? undefined :this.formatter.formatFromNgbDatePicker(this.returnDate.value).getTime() / 1000;

    (await this.apiService.lockingMedia.update(this.selectedItem!)).subscribe({
      next: value => {
        this.itemManager.updateItem(value);
        this.resetForms();
        returnToList();
      }
    });
  }

  onSearch(search: string) {
    this.searchQuery = search;
    this.searchFilter.triggerItemUpdate();
  }

  private async onDelete(returnToList: () => void) {
    if (await this.apiService.lockingMedia.delete(this.selectedItem!.uuid)) {
      this.itemManager.removeItemsById(this.selectedItem!.uuid);
      this.resetForms();
      returnToList();
    }
  }

  get stateTranslationText():string {
    return `LOCKING_MEDIA.STATE.${[...MEDIA_STATE.filter(value => this.lockingMediaForm.get('state')?.value == `${value.id}`), MEDIA_STATE[0]][0].value.toUpperCase()}`;
  }

  get returnDateInvalid(): boolean {
    if (this.handoutDate.value == null || this.returnDate.value == null) {
      return false;
    }
    return this.formatter.formatFromNgbDatePicker(this.handoutDate.value).getTime() > this.formatter.formatFromNgbDatePicker(this.returnDate.value).getTime();
  }

  protected readonly MEDIA_STATE = MEDIA_STATE;
}
