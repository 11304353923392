import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {ItemListFilter, ItemListItem, ItemListMapper} from "../../../shared/item-list/ItemListItem";
import {PendingChangesBlocker} from "../../../core/guards/pending-changes-view.guard";
import {ItemAction, ItemSaveAction, ListEditAction, ListViewAction} from "../../../shared/models/Actions";
import {ApiService} from "../../../core/services/api-service/api.service";
import {ListActionEvent} from "../../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../../shared/models/ItemActionEvent";
import {TranslateService} from "@ngx-translate/core";
import {LockingDeviceFormComponent} from "./forms/locking-device-form/locking-device-form.component";
import {
  LockingDeviceParamsFormComponent
} from "./forms/locking-device-params-form/locking-device-params-form.component";
import {ItemManager} from "../../../shared/item-list/ItemManager";
import {SubmitUtils} from "../../../shared/util/SubmitUtils";
import {
  LockingDeviceCommissioningComponent
} from "../modals/locking-device-commissioning/locking-device-commissioning.component";
import {debounceTime, firstValueFrom, take} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ModalCloseCause} from "../../../core/enums/modalCloseCause";
import {IoBridgeFlow} from "../../../core/enums/ioBridgeFlow";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CommissionManager} from "../../../core/services/auth-service/support-services/CommissionManager";
import {IoBridgeStatus} from "../../../core/enums/ioBridgeStatus";
import {LockingDeviceDto} from "../../../shared/entities/locking-device/LockingDeviceDto";
import {SessionManager} from "../../../core/services/auth-service/support-services/SessionManager";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {ModalContainerContent} from "../../../shared/notification/modal/modal-container.class";
import {ModalService} from "../../../shared/notification/modal/modal.service";
import {ChangeDetectorChanges} from "../../../shared/util/change-detector/ChangeDetectorChanges";

@Component({
  selector: 'app-locking-device-devices',
  templateUrl: './locking-device-devices.component.html',
  styleUrls: ['./locking-device-devices.component.scss']
})
export class LockingDeviceDevicesComponent implements OnInit, ItemListMapper<LockingDeviceDto>, PendingChangesBlocker {
  _isLoading: boolean = true;
  get isLoading():boolean {
    return this._isLoading;
  }
  @ViewChild(LockingDeviceFormComponent) lockingDeviceForm!: LockingDeviceFormComponent
  @ViewChild(LockingDeviceParamsFormComponent) lockingDeviceParamsForm!: LockingDeviceParamsFormComponent

  @Input() set onSearch(searchString: string) {
    this.searchQuery = searchString
    this.searchFilter.triggerItemUpdate()
  }

  @Input() readonlyLicenseInvalid: boolean = false;

  @Output() onUpdate = new EventEmitter<void>();

  accessReadonly: boolean = false;
  lockingMediaReadonly: boolean = false;
  programmingAllowed: boolean = false;

  itemManager: ItemManager<LockingDeviceDto>
  selectedItem?: LockingDeviceDto;

  itemSelected = false

  pendingFormChanges = false

  selectedNavTab: 'lockingDevice' | 'deviceParams' | 'assignedGroups' = 'lockingDevice'

  // Actions
  saveAction = new ItemSaveAction()
  generateResetJobAction = new ItemAction(
    "LOCKING_DEVICES.DEVICES.ITEM_LIST.GENERATE_RESET_JOB.TITLE",
    async () => {
      const modalContent: ModalContainerContent = new ModalContainerContent(
        this.translate.instant('LOCKING_DEVICES.DEVICES.ITEM_LIST.GENERATE_RESET_JOB.TITLE'),
        this.translate.instant('LOCKING_DEVICES.DEVICES.ITEM_LIST.GENERATE_RESET_JOB.MESSAGE', {name: this.selectedItem!.shortName})
      );
      if (await this.alertService.openModal(modalContent)) {
        if ((await firstValueFrom(await this.apiService.job.generateResetJob(this.selectedItem!.uuid))).ok) {
          this.generateResetJobAction.disabled = true
          this.handleSuccessGenerateJob()
        }
      }
    },
    false,
    "btn-outline-secondary",
    "mdi mdi-sync");

  generateUnbindAction: ItemAction = new ItemAction(
    "PROGRAMMING.BUTTONS.DISCONNECT",
    (): void => {
    },
    false,
    "btn-outline-danger",
    "mdi mdi-door-open");

  generateReplaceAction: ItemAction = new ItemAction(
    "PROGRAMMING.BUTTONS.REPLACE",
    (): void => {
    },
    false,
    "btn-outline-secondary",
    "mdi mdi-auto-fix");


  getAccessReadonly() { return this.apiService.lockingDevice.isReadonly() || this.readonlyLicenseInvalid }
  getLockingMediaReadonly() { return this.apiService.lockingMedia.isReadonly() }
  getProgrammingAllowed() { return this.apiService.lockingDevice.isProgrammingAllowed() }

  typeaheadIndex = new Set<string>()
  get searchEntries() { return [...this.typeaheadIndex.values()] }
  searchQuery: string = ""
  searchFilter = new ItemListFilter<LockingDeviceDto>(item => {
    return String(item.shortName || "").toLowerCase().includes(this.searchQuery.toLowerCase())
  })

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private alertService: ModalService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
    this.saveAction.disabled = true;
    this.itemManager = new ItemManager<LockingDeviceDto>(this, notification);
  }

  async ngOnInit() {
    this._isLoading = true;
    this.itemManager.setItems(await firstValueFrom(await this.apiService.lockingDevice.getAll()));
    this.itemManager.forEach((value => {
      this.typeaheadIndex.add(value.name)
    }))
    this.onUpdate.emit();
    this.accessReadonly = this.getAccessReadonly();
    this.lockingMediaReadonly = this.getLockingMediaReadonly();
    this.programmingAllowed = this.getProgrammingAllowed();
    this._isLoading = false;
  }

  private get hasChanges():boolean {
    return new ChangeDetectorChanges(
      this.lockingDeviceForm?.changeDetector,
      this.lockingDeviceForm?.assemblyDate,
      ...this.lockingDeviceParamsForm?.changeDetectorChanges.detectors
    ).hasChanges;
  }

  handlePendingChanges() {
    this.saveAction.disabled = !this.hasChanges || !this.lockingDeviceForm?.assemblyDate.isValid || !this.lockingDeviceParamsForm?.changeDetectorChanges.isValid;
    this.pendingFormChanges = this.hasChanges
    this.onUpdate.emit()
  }

  hasPendingChanges(): boolean {
    return this.pendingFormChanges
  }

  getBatteryImage(item: LockingDeviceDto = this.selectedItem!): string {
    return `assets/ces/locking-device/battery/BatteryCondition-${this.apiService.lockingDevice.getBatteryCondition(item?.batteryConditionId)}.svg`;
  }

  mapToItemList(item: LockingDeviceDto): ItemListItem<LockingDeviceDto> {
    const imageName = this.apiService.lockingDevice.getImageNameForDeviceType(item.deviceTypeId)

    let listItem = new ItemListItem(item.uuid, item.shortName, item)
      .addInfo(item.deviceUid.toString())
      .addAction(this.lockingMediaReadonly ? new ListViewAction() : new ListEditAction())
      .setImage(`assets/ces/locking-device/${imageName}.svg`)

    if (this.apiService.lockingDevice.shouldShowImageForDeviceType(item.deviceTypeId)) {
      listItem.setOverlayIcon(this.getBatteryImage(item))
    }

    // Badges
    if (item.changesPending) {
      listItem.addBadge("ces ces-locking-device pending-changes", "rounded-pill badge-soft-primary",
        this.translate.instant("LOCKING_DEVICES.DEVICES.BADGES.PENDING_CHANGES"))
    }
    if (item.changesProcessing) {
      listItem.addBadge("ces ces-locking-device processing-changes", "rounded-pill badge-soft-primary",
        this.translate.instant("LOCKING_DEVICES.DEVICES.BADGES.PROCESSING_CHANGES"),)
    }

    return listItem
  }

  onReturnEvent() {
    this.selectedNavTab = 'lockingDevice';
    this.selectedItem = undefined;

    this.lockingDeviceForm.reset();

    this.handlePendingChanges()
    this.onUpdate.emit()
  }

  async onSelectEvent(actionEvent: ListActionEvent<LockingDeviceDto>) {
    if (actionEvent == undefined) return

    this.selectedItem = actionEvent.item
    this.onUpdate.emit()

    if (SessionManager.getInstance().isAdminOrHigher && CommissionManager.getInstance().bindingProcess.ioBridgeStatus == IoBridgeStatus.CONNECTED) {
      if (environment.featureFlags.systemBindingEnabled) {
        actionEvent.addItemAction(this.generateUnbindAction);
        actionEvent.addItemAction(this.generateReplaceAction);
      }
    }
    if (this.programmingAllowed) {
      actionEvent.addItemAction(this.generateResetJobAction)
      this.generateResetJobAction.disabled = false
    }
    if (!this.accessReadonly) {
      actionEvent.addItemAction(this.saveAction)
    }
  }

  get selectedUuid(): string {
    return this.selectedItem?.uuid ? this.selectedItem?.uuid : '';
  }

  async onEditEvent(actionEvent: ItemActionEvent<LockingDeviceDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action == this.saveAction) {
      await this.onSubmit(actionEvent.returnToList);
    } else if (actionEvent.action == this.generateUnbindAction) {
      await this.onUnbind(actionEvent.returnToList);
    } else if (actionEvent.action == this.generateReplaceAction) {
      await this.onBindRepair(actionEvent.returnToList);
    }
  }

  async onSubmit(returnToList: () => void) {

    if (SubmitUtils.checkControls(
      this.notification,
      true,
      this.lockingDeviceForm.lockingDeviceForm) || SubmitUtils.reflectCheck(
      this.notification,
      true,
      this.lockingDeviceParamsForm.changeDetectorChanges.isValid)) {
      return;
    }

    let success: boolean = true;
    let updatedLockingDevice: LockingDeviceDto | undefined = this.lockingDeviceForm.getLockingDeviceDto();


    if (this.lockingDeviceForm.changed) {
      updatedLockingDevice = await firstValueFrom(await this.apiService.lockingDevice.update(updatedLockingDevice));
      success = updatedLockingDevice != null;
    }
    if (success && this.lockingDeviceParamsForm.changeDetectorChanges.hasChanges) {
      success = await firstValueFrom(await this.apiService.lockingDevice.updateParameters(this.lockingDeviceParamsForm.getLockingDeviceParamsDto()!)) != null;
    }

    if (success && updatedLockingDevice != null) {
      updatedLockingDevice.changesPending = true;
    }

    if (success) {
      // only update items if device has been changed (!= null)
      if (updatedLockingDevice != null) {
        this.itemManager.updateItem(updatedLockingDevice)
      } else {
        this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.UPDATE')
      }
      returnToList()
      this.onReturnEvent()
    }
  }

  async onUnbind(returnToList: () => void) {
    CommissionManager.getInstance().initUnbind(this.selectedItem!);
    this.modalComponent(returnToList);
  }

  async onBindRepair(returnToList: () => void) {
    CommissionManager.getInstance().initRebind(this.selectedItem!);
    this.modalComponent(returnToList);
  }

  private async modalComponent(returnToList: () => void) {
    this.modalService.open(LockingDeviceCommissioningComponent).closed.pipe(debounceTime(500)).subscribe(async closeReason => {
      switch (typeof closeReason) {
        case "number":
        case "object":
          if (closeReason == (ModalCloseCause.SUCCESS || 3)) {
            this.removeCurrentItem();
            if (CommissionManager.getInstance().bindingProcess.flow == IoBridgeFlow.REBIND) {
              await this.ngOnInit();
            }
            returnToList();
            this.onReturnEvent();
          }
          break;
        case "string":
          let item = this.removeCurrentItem();
          item.serialNumber = closeReason;
          this.selectedItem = item as LockingDeviceDto;
          this.itemManager.addItems(item as LockingDeviceDto);
          const changesWereMade: boolean = this.lockingDeviceForm.changed || this.lockingDeviceParamsForm.changeDetectorChanges.hasChanges;
          this.lockingDeviceForm.lockingDeviceForm.patchValue({serialNumber: item.serialNumber});
          if (!changesWereMade) {
            this.lockingDeviceForm.lockingDeviceForm.markAsUntouched();
            this.lockingDeviceForm.changeDetector.refresh();
          }
          break;
      }
    });
  }

  // any instead of LockingDeviceDto so the serialNumber can be set
  private removeCurrentItem(): any {
    let selectedItemPuffer: any = this.selectedItem!;
    this.itemManager.removeItems(this.selectedItem!);
    return selectedItemPuffer
  }

  private handleSuccessGenerateJob() {
    this.selectedItem!.changesPending = false;
    this.selectedItem!.changesProcessing = true;
    this.itemManager.updateItem(this.selectedItem!);
  }
}
