<ng-template #infoItemContent>
  <div *ngFor="let item of infoItems" class="text-primary text-center text-truncate">
    <a [routerLink]="item.link">{{ item.key | translate }}</a>
  </div>
</ng-template>

<ng-template #infoItemPopup>
  <div class="d-flex flex-column gap-2">
    <ng-container [ngTemplateOutlet]="infoItemContent"></ng-container>
    <div class="text-center text-muted">{{appService.version}}</div>
  </div>
</ng-template>

<footer class="position-fixed left-0 bottom-0 w-100" [ngClass]="{'footer-shadow': scrollablePage}">
  <div class="font-size-13 px-2 gap-1 px-2 px-lg-0 p-1">

    <div id="footer-container" class="d-flex flex-column align-items-center justify-content-around ">

      <span class="black-footer-ces">
        © {{year}} C.Ed. Schulte GmbH Zylinderschlossfabrik
      </span>

      <div class="d-flex flex-row flex-nowrap justify-content-around">

        <div class="d-none d-xs-none d-lg-flex flex-row flex-nowrap justify-content-around gap-4">
          <ng-container [ngTemplateOutlet]="infoItemContent"></ng-container>

          <div class="position-absolute end-0 pe-1 text-muted">
            {{appService.version}}
          </div>
        </div>

        <button class="btn d-flex d-lg-none text-primary font-size-13 p-1"
                [ngbPopover]="infoItemPopup" [autoClose]="true" popoverClass="p-2 font-size-13 mobile">
          {{ "LEGAL.FOOTER_TEXT" | translate }}
        </button>

      </div>
    </div>

  </div>

</footer>
