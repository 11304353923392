import {AfterViewInit, Component, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {IoBridgeService} from "../../../../core/services/io-bridge-service/io-bridge.service";
import {DownloadComponent} from "./download/download.component";
import {debounceTime, Subject} from "rxjs";
import {IoBridgeStatus} from "../../../../core/enums/ioBridgeStatus";
import {environment} from "../../../../../environments/environment";
import {CommissionManager} from "../../../../core/services/auth-service/support-services/CommissionManager";
import {SessionManager} from "../../../../core/services/auth-service/support-services/SessionManager";

@Component({
  selector: 'io-bridge-context-menu',
  templateUrl: './io-bridge.component.html',
  styleUrls: ['./io-bridge.component.scss']
})
export class IoBridgeComponent implements OnInit {

  private lastSessionData: any = undefined;
  private sessionData = SessionManager.getInstance().sessionDataSubject;

  @ViewChild(DownloadComponent) downloadComponent!: DownloadComponent;

  constructor(private ioBridgeService: IoBridgeService) {
  }

  get iobStatusShort(): IoBridgeStatus {
    return CommissionManager.getInstance().bindingProcess.ioBridgeStatus;
  }

  get dtwStatusShort(): IoBridgeStatus {
    return CommissionManager.getInstance().bindingProcess.dtwStatus;
  }

  get isUsbAvailable(): boolean {
    return CommissionManager.getInstance().bindingProcess.dtwStatus == IoBridgeStatus.CONNECTED;
  }

  get isIoBridgeAvailable(): boolean {
    return this.iobStatusShort == IoBridgeStatus.CONNECTED || this.iobStatusShort == IoBridgeStatus.UPDATE_REQUIRED;
  }

  get ioBridgeNeedsUpdate(): boolean {
    return this.iobStatusShort == IoBridgeStatus.UPDATE_REQUIRED;
  }

  protected disableTestDTW: boolean = false;

  testDTW() {
    this.disableTestDTW = true
    this.ioBridgeService.fetchTestDTW().then(() => this.disableTestDTW = false)
  }

  ngOnInit(): void {
    if (!environment.featureFlags.systemBindingEnabled) {
      return;
    }

    const sessionManager: SessionManager = SessionManager.getInstance();
    if (sessionManager.isValidSession() && !sessionManager.isAdminOrHigher) {
      return;
    }

    this.ioBridgeService.iobConnectionChecker();

    this.sessionData.pipe(debounceTime(50)).subscribe({
      next: value => {
        this.ioBridgeService.pause = value == undefined || !sessionManager.isAdminOrHigher || !sessionManager.isValidSession();
      }
    });
  }

}
