import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChangeDetectorForm} from '../../../../../shared/util/change-detector/ChangeDetectorForm';
import {ApiService} from '../../../../../core/services/api-service/api.service';
import {FormatterService} from '../../../../../core/services/formatter-service/formatter.service';
import {SessionManager} from "../../../../../core/services/auth-service/support-services/SessionManager";
import {debounceTime} from "rxjs";
import {LockingDeviceDto} from "../../../../../shared/entities/locking-device/LockingDeviceDto";
import {ASSEMBLY_STATE} from "../../../../../shared/lookup/assembly.lookup";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
  selector: 'app-locking-device-form',
  templateUrl: './locking-device-form.component.html',
  styleUrls: ['./locking-device-form.component.scss'],
})
export class LockingDeviceFormComponent implements OnInit {
  @Input() accessReadonly = false;

  _selectedItem?: LockingDeviceDto;
  assemblyDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});

  @Input() set lockingDevice(item: LockingDeviceDto) {
    if (item == null) {
      return;
    }

    this._selectedItem = item;

    this.lockingDeviceForm.patchValue(
      {
        // General
        shortName: item.shortName,
        description: item.description,
        uuid: item.uuid,
        uid: item.deviceUid,
        deviceUid: item.deviceUid,
        articleNumber: item.articleNumber,
        // Information
        technicalId: item.rfidTypeId == 1 ? 'Mifare' : 'LEGIC',
        battery: item.batteryConditionId,
        serialNumber: item.serialNumber,
        firmwareVersion: item.firmwareVersion,
        hardwareVersion: item.hardwareVersion,
        lengthInner: `${item.lengthInner! / 10} mm`,
        lengthOuter: `${item.lengthOuter! / 10} mm`,
        handleDirectionId: item.handleDirectionId,
        handleInnerTypeId: item.handleInnerTypeId,
        handleOuterTypeId: item.handleOuterTypeId,
        cylinderDistanceId: `${item.cylinderDistanceId} mm`,
        squareSizeId: `${item.squareSizeId} mm`,
        assemblyState: item.assemblyStateId,
        // Location
        building: item.locationBuilding,
        level: item.locationLevel,
        room: item.locationRoom,
        door: item.locationDoor,
      },
      {emitEvent: false}
    );

    this.assemblyDate = new ChangeDetectorValue(this.formatter.formatToNgbDatePicker(item.assemblyDate), () => {this.onChanges.emit()});

    this.showHardwareVersion = item.hardwareVersion != null;
    this.showLength =
      this.apiService.lockingDevice.shouldShowLengthForDeviceType(
        item.deviceTypeId
      );
    this.showHandleInfo =
      this.apiService.lockingDevice.shouldShowHandleInfoForDeviceType(
        item.deviceTypeId
      );
    this.showBatteryIcon =
      this.apiService.lockingDevice.shouldShowImageForDeviceType(
        item.deviceTypeId
      );
    this.batteryImage = this.getBatteryImage();

    this.lockingDeviceForm.markAsUntouched();
    this.changeDetector.refresh();
  }

  @Output() onChanges = new EventEmitter<boolean>();

  // Forms
  lockingDeviceForm: FormGroup;
  changeDetector: ChangeDetectorForm;

  // Values
  batteryImage = '';
  showHardwareVersion = false;
  showLength = false;
  showHandleInfo = false;
  showBatteryIcon = false;

  // Length Validator
  validatorMaxLength64: number = 64;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private formatter: FormatterService
  ) {
    this.lockingDeviceForm = this.formBuilder.group({
      // General
      shortName: [
        '',
        [Validators.required, Validators.maxLength(this.validatorMaxLength64)],
      ],
      description: ['', [Validators.maxLength(this.validatorMaxLength64)]],
      uid: ['', []],
      uuid: ['', []],
      deviceUid: [-1, []],
      articleNumber: ['', []],
      // Information
      serialNumber: ['', []],
      firmwareVersion: ['', []],
      hardwareVersion: ['', []],
      technicalId: ['', []],
      battery: [0, []],
      // length
      lengthInner: ['', []],
      lengthOuter: ['', []],
      // handle
      handleDirectionId: [0, []],
      handleInnerTypeId: [0, []],
      handleOuterTypeId: [0, []],
      cylinderDistanceId: [0, []],
      squareSizeId: [0, []],
      // assembly
      assemblyState: [0, []],
      // Location
      building: ['', []],
      level: ['', []],
      room: ['', []],
      door: ['', []],
    });

    this.changeDetector = new ChangeDetectorForm(this.lockingDeviceForm, () => {
      this.onChanges.emit(this.changeDetector.changed);
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.lockingDeviceForm.controls;
  }

  getBatteryImage(): string {
    const imageName: string =
      this.apiService.lockingDevice.getBatteryCondition(
        this._selectedItem?.batteryConditionId || 0
      );
    const themeDesign = document.body.getAttribute('data-bs-theme');
    if (themeDesign != null && themeDesign.includes('black')) {
      return `assets/ces/locking-device/battery/BatteryCondition-${imageName}-black.svg`;
    }
    return `assets/ces/locking-device/battery/BatteryCondition-${imageName}.svg`;
  }

  get changed(): boolean {
    return this.changeDetector.changed || this.assemblyDate.hasChanges;
  }

  get invalid(): boolean {
    return this.lockingDeviceForm.invalid;
  }

  reset() {
    this.lockingDeviceForm.reset();
    this.lockingDeviceForm.markAsUntouched();
    this.changeDetector.refresh();
  }

  getLockingDeviceDto(): LockingDeviceDto {
    // update values
    this._selectedItem!.shortName =
      this.lockingDeviceForm.get('shortName')?.value;
    this._selectedItem!.description =
      this.lockingDeviceForm.get('description')?.value;
    this._selectedItem!.assemblyStateId = this.lockingDeviceForm.get('assemblyState')?.value;
    this._selectedItem!.assemblyDate = this.formatter.formatFromNgbDatePicker(this.assemblyDate.value).getTime() / 1000;
    this._selectedItem!.locationBuilding =
      this.lockingDeviceForm.get('building')?.value;
    this._selectedItem!.locationLevel =
      this.lockingDeviceForm.get('level')?.value;
    this._selectedItem!.locationRoom =
      this.lockingDeviceForm.get('room')?.value;
    this._selectedItem!.locationDoor =
      this.lockingDeviceForm.get('door')?.value;

    return this._selectedItem!;
  }

  get assemblyStateTranslationText(): string {
    return `LOCKING_DEVICES.DEVICES.ASSEMBLY_STATE.${[...ASSEMBLY_STATE.filter(value => this.lockingDeviceForm.get('assemblyState')?.value == `${value.id}`),ASSEMBLY_STATE[0]][0].value.toUpperCase()}`;
  }

  protected readonly ASSEMBLY_STATE = ASSEMBLY_STATE;
}
