import {inject, Injectable, TemplateRef} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

export enum NotificationTypes {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export interface Toast {
  textOrTpl: string | TemplateRef<any>;
  classname: string;
  type: NotificationTypes
  delay?: number;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  translate = inject(TranslateService);
  toasts: Toast[] = [];

  show(textOrTpl: string, options: any = {}) {
    this.toasts = [];
    textOrTpl = this.translate.instant(textOrTpl);
    this.toasts.push({ textOrTpl, ...options });
  }

  showError(textOrTpl: string = 'NOTIFICATION.TOAST.ERROR.DEFAULT') {
    this.toasts = [];
    textOrTpl = this.translate.instant(textOrTpl);
    this.toasts.push({ textOrTpl, type: NotificationTypes.DANGER, classname: 'toast-body-danger', delay: 5000 });
  }

  showSuccess(textOrTpl: string = 'NOTIFICATION.TOAST.SUCCESS.DEFAULT') {
    this.toasts = [];
    textOrTpl = this.translate.instant(textOrTpl);
    this.toasts.push({ textOrTpl, type: NotificationTypes.SUCCESS, classname: 'toast-body-success', delay: 5000 });
  }

  showWarning(textOrTpl: string) {
    this.toasts = [];
    textOrTpl = this.translate.instant(textOrTpl);
    this.toasts.push({ textOrTpl, type: NotificationTypes.WARNING, classname: 'toast-body-warning', delay: 5000 });
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
