import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ItemListComponent} from "../../../shared/item-list/item-list.component";
import {ItemManager} from "../../../shared/item-list/ItemManager";
import {ItemSaveAction, ListEditAction, MultiDeleteAction, MultiListAction} from "../../../shared/models/Actions";
import {ItemListFilter, ItemListItem, ItemListMapper} from "../../../shared/item-list/ItemListItem";
import {UserFormComponent} from "./user-forms/user-form/user-form.component";
import {UserAccessProfileFormComponent} from "./user-forms/user-access-profile-form/user-access-profile-form.component";
import {UserLockingMediaFormComponent} from "./user-forms/user-locking-media-form/user-locking-media-form.component";
import {ApiService} from "../../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {MultiListActionEvent} from "../../../shared/models/MultiListActionEvent";
import {ListActionEvent} from "../../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../../shared/models/ItemActionEvent";
import {SubmitUtils} from "../../../shared/util/SubmitUtils";
import {PendingChangesBlocker} from "../../../core/guards/pending-changes-view.guard";
import {SessionManager} from "../../../core/services/auth-service/support-services/SessionManager";
import {UserRequestDto} from "../../../shared/entities/user/UserRequestDto";
import {firstValueFrom} from "rxjs";
import {ROLE} from "../../../shared/lookup/role.lookup";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {ChangeDetectorChanges} from "../../../shared/util/change-detector/ChangeDetectorChanges";
import {ChangeDetectorValue} from "../../../shared/util/change-detector/ChangeDetectorValue";
import {UserWithImage} from "../../../shared/util/change-detector/models/user-with-image";
import {cloneDeep} from "lodash";

export const defaultImage: string = function (): string {
  const themeDesign = document.body.getAttribute("data-bs-theme");
  if (themeDesign != null && themeDesign.includes('black')) {
    return "assets/ces/user/user-black.svg"
  }
  return "assets/ces/user/user.svg"
}()

@Component({
  selector: 'app-user-users',
  templateUrl: './user-users.component.html',
  styleUrls: ['./user-users.component.scss']
})
export class UserUsersComponent implements OnInit, ItemListMapper<UserWithImage>, PendingChangesBlocker {
  _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  @ViewChild(ItemListComponent) appItemList!: ItemListComponent<UserWithImage>

  @Input() set onSearch(search: string) {
    this.searchQuery = search
    this.searchFilter.triggerItemUpdate()
  }

  @Output() onUpdate = new EventEmitter<void>()

  itemManager: ItemManager<UserWithImage>
  selectedItem: UserWithImage | undefined;
  itemSelected = false

  // Actions
  saveAction = new ItemSaveAction()

  multiSelectActions: MultiListAction[] = []
  multiDeleteAction = new MultiDeleteAction()

  // Data
  userAccessProfileUuids: ChangeDetectorValue = new ChangeDetectorValue(['']);
  userLockingMediaUuids: ChangeDetectorValue = new ChangeDetectorValue(['']);

  // License
  licenseTypeId: number = 1;
  licenseIsValidForBusiness: boolean = true;

  // Search
  typeaheadIndex = new Set<string>()

  canChangeUserRole: boolean = false
  canChangeUserAccessSettings: boolean = false
  isLicenseExpiredForUser: boolean = false

  get searchEntries() {
    return [...this.typeaheadIndex.values()]
  }

  searchQuery: string = ""
  searchFilter = new ItemListFilter<UserRequestDto>(item => {
    return String(`${item.firstName} ${item.lastName}`).toLowerCase().includes(this.searchQuery.toLowerCase())
  })

  // Tabs
  selectedNavTab: 'user' | 'accessProfiles' | 'lockingMedia' = 'user'

  pendingFormChanges = false

  // Forms
  @ViewChild(UserFormComponent) userForm!: UserFormComponent
  @ViewChild(UserAccessProfileFormComponent) userAccessProfileForm!: UserAccessProfileFormComponent
  @ViewChild(UserLockingMediaFormComponent) userLockingMediaForm!: UserLockingMediaFormComponent

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private translate: TranslateService
  ) {
    this.itemManager = new ItemManager<UserWithImage>(this, notification);
  }


  async ngOnInit() {
    this._isLoading = true;
    this.canChangeUserAccessSettings = this.getCanChangeUserAccessSettings();
    this.isLicenseExpiredForUser = await this.getIsLicenseExpiredForUser()
    await this.loadItemsAndBuildTypeahead()

    if (this.apiService.user.canDelete) {
      this.multiSelectActions.push(this.multiDeleteAction)
    }

    this.licenseIsValidForBusiness = SessionManager.getInstance().isLicenseBusiness && !SessionManager.getInstance().isLicenseExpired;
    this.licenseTypeId = SessionManager.getInstance().isLicenseBusiness ? 2 : 1;
  }

  async loadItemsAndBuildTypeahead() {
    this.apiService.user.getAll().then(value => value.subscribe({
      next: userDtos => {
        this.itemManager.setItems(userDtos as UserWithImage[]);
        this.typeaheadIndex.clear()
        this.itemManager.forEach(value => {
          this.typeaheadIndex.add(`${value.item.firstName} ${value.item.lastName}`)
        })

        this.onUpdate.emit();
        this._isLoading = false;
      }
    }));
  }

  // Access
  async getCanChangeUserRole(): Promise<boolean> {
    if (this.selectedItem == null) {
      return false;
    }
    if (SessionManager.getInstance().sessionCredentials!.userUUID == this.selectedItem.uuid.toLowerCase()) {
      return false;
    }
    return SessionManager.getInstance().isAdminOrHigher;
  }

  getCanChangeUserAccessSettings(): boolean {
    return this.apiService.user.canChange;
  }

  async getIsLicenseExpiredForUser(): Promise<boolean> {
    return (!this.licenseIsValidForBusiness && this.licenseTypeId > 1);
  }

  getLicenseTypeId(): number {
    return this.licenseTypeId;
  }

  private get changeDetector(): ChangeDetectorChanges {
    return new ChangeDetectorChanges(
      this.userForm.userValue,
      this.userForm.userImageChanged,
      this.userAccessProfileForm.userAccessProfiles,
      this.userLockingMediaForm.userLockingMedia);
  }

  // Interfaces
  checkPendingChanges() {
    this.pendingFormChanges = this.changeDetector.hasChanges;
    this.saveAction.disabled = !this.changeDetector.hasChanges || !this.changeDetector.isValid;
  }

  hasPendingChanges(): boolean {
    return this.pendingFormChanges
  }

  mapToItemList(item: UserWithImage): ItemListItem<UserWithImage> {
    let listItem = new ItemListItem<UserWithImage>(
      item.uuid,
      `${item.firstName} ${item.lastName}`,
      item);

    listItem.addInfo(this.translate.instant([...ROLE.filter(role => role.id == item.roleId).map(user => `USERS.USERS.ROLE.${user.value.toUpperCase()}`), 'USERS.USERS.ROLE.UNKNOWN'][0]));

    // set default image
    item.userImage = defaultImage;
    listItem.setImage(defaultImage);

    // load image if available
    if (item.imageAvailable) {
      // TODO sceleton
      item.userImage = defaultImage;
      this.apiService.user.getUserImage(item.uuid).then((base64Image) => {
        if (base64Image == null) return;
        item.userImage = `data:image/*;base64,${base64Image}`;
        listItem.setImage(item.userImage);
      })
    }

    listItem.addAction(new ListEditAction());

    return listItem;
  }

  async onMultiDeleteAction(event: MultiListActionEvent<UserRequestDto>) {
    let allSuccess = true
    if (event.items.length == 1 && (SessionManager.getInstance().sessionCredentials!.userUUID == event.items[0].uuid)) {
      this.notification.showError(this.translate.instant("USERS.USERS.ACTION.DELETE_SELF"))
      return;
    }

    for (const user of event.items) {
      if (!await this.apiService.user.delete(user.uuid, true)) {
        allSuccess = false
      }
    }

    await this.loadItemsAndBuildTypeahead()

    if (allSuccess) {
      this.notification.showSuccess(`NOTIFICATION.TOAST.SUCCESS.DELETE`)
      return
    }

    this.notification.showWarning(this.translate.instant("USERS.USERS.ACTION.DELETE_WARNING"))
  }

  onAddUser() {
    let user: UserWithImage = new UserWithImage();
    this.selectedItem = user;
    user.userImage = defaultImage;

    this.onUpdate.emit();

    const listItem = new ItemListItem<UserWithImage>(user.uuid, this.translate.instant("USERS.USERS.ACTION.NEW_USER_NAME"), user);

    // TODO lookup
    listItem.addInfo(this.translate.instant("USERS.USERS.ROLE.UNKNOWN"));


    listItem.setImage(defaultImage);

    this.appItemList.setSelectedListItem(listItem, new ItemSaveAction());
    this.setAssignedUuids();
  }

  onResetView() {
    this.selectedNavTab = 'user'

    this.userForm.reset();

    this.userAccessProfileForm.reset()
    this.userLockingMediaForm.reset()

    this.selectedItem = undefined;

    this.onUpdate.emit()
  }

  async onSelectEvent(actionEvent: ListActionEvent<UserRequestDto>) {
    if (actionEvent == undefined) return

    await this.onSelect(actionEvent.item);

    actionEvent.addItemAction(this.saveAction);
  }

  private async onSelect(item: UserRequestDto) {
    let user: UserWithImage = new UserWithImage();
    user.values = cloneDeep(await firstValueFrom(await this.apiService.user.getUser(item.uuid)));
    this.selectedItem = user;
    this.selectedItem.userImage = defaultImage;
    if (this.selectedItem.imageAvailable && this.itemManager.items.has(this.selectedItem.uuid)) {
      this.selectedItem.userImage = (this.itemManager.items.get(this.selectedItem.uuid) || {image: defaultImage}).image;
    }

    this.canChangeUserRole = await this.getCanChangeUserRole();

    this.setAssignedUuids();
    this.onUpdate.emit();
  }

  private setAssignedUuids() {
    this.userAccessProfileUuids = new ChangeDetectorValue(this.objectToStringArray(this.selectedItem?.accessProfiles), () => {
      this.checkPendingChanges();
    });
    this.userLockingMediaUuids = new ChangeDetectorValue(this.objectToStringArray(this.selectedItem?.media), () => {
      this.checkPendingChanges();
    });
  }

  async onEditEvent(actionEvent: ItemActionEvent<UserRequestDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList)
    }
  }

  async onSubmit(returnToList: () => void) {

    if (SubmitUtils.reflectCheck(this.notification, true, this.changeDetector.hasChanges, this.changeDetector.isValid)) {
      return
    }

    let success = true
    let user: UserWithImage = this.userForm.userValue.value;

    // add / update user
    if (this.selectedItem != undefined) {

      user.accessProfiles = this.userAccessProfileForm.userAccessProfiles.value || [];
      user.media = this.userLockingMediaForm.userLockingMedia.value || [];

      if (user.uuid == "") {
        user.values = await firstValueFrom(await this.apiService.user.add((user as UserWithImage).toUserRequestDto));
      } else {
        user.values = await firstValueFrom(await this.apiService.user.update((user as UserWithImage).toUserRequestDto));
      }

      success = (user.uuid != null && user.uuid.length > 0);
    }

    // update user image
    if (success && this.userForm.requireUserImageUpdate()) {
      if (user.uuid == "") {
        user.uuid = this.userForm.getUserRequestDto().uuid;
        user.imageAvailable = this.userForm.getUserRequestDto().imageAvailable;
      }
      if (this.userForm.hasUserImage) {
        const imageBlob = this.userForm.getUserImage();
        if (imageBlob == undefined) {
          user.imageAvailable = false;
        } else {
          success = await this.apiService.user.uploadUserImage(user.uuid, imageBlob);
          user.imageAvailable = true;
          SessionManager.getInstance().sessionCredentials
        }
      } else if (user.imageAvailable) {
        success = await this.apiService.user.deleteUserImage(user.uuid)
        user.imageAvailable = false
      }

      if (success && SessionManager.getInstance().sessionCredentials!.userUUID == user.uuid) {
        this.apiService.user.updateCurrentUserImage(user.imageAvailable ? `data:image/*;base64,${this.userForm.getUserImageData()}` : defaultImage)
      }
    }

    if (!success) {
      this.notification.showError();
    } else {
      this.notification.showSuccess();
      this.loadItemsAndBuildTypeahead();

      returnToList();
      this.onResetView();
    }
  }

  private objectToStringArray(o: any[] | undefined): any[] {
    if (o == undefined || o.length == 0) {
      return [];
    }
    const tester: any = o[0];
    if (typeof tester === 'string') {
      return o as string[];
    }
    if (tester.uuid != undefined) {
      return o.map(value => value.uuid);
    }
    return [];
  }
}
