// this file is automatically generated by git.version.ts script
export const versions = {
      beta: undefined,
      branch: 'develop',
      buildTime: '2024-10-11T10:11:56.354Z',
      package: '@ces/cesentry-web-client',
      revision: '9c137975',
      tag: 'v1.0.3',
      version: '1.1.1',
     };
