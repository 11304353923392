import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
  NgbDropdownModule,
  NgbNavModule,
  NgbProgressbar,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LightboxModule} from 'ngx-lightbox';

import {PagesRoutingModule} from './pages-routing.module';
import {SharedModule} from '../shared/shared.module';
import {SystemComponent} from './system/system.component';
import {UsersComponent} from './users/users.component';
import {LockingMediaComponent} from './locking-media/locking-media.component';
import {TimeModelsComponent} from './time-models/time-models.component';
import {AccessProfilesComponent} from './access-profiles/access-profiles.component';
import {LogbookComponent} from './logbook/logbook.component';
import {TranslateModule} from "@ngx-translate/core";
import {CoreModule} from "../core/core.module";
import {AccessProfileFormsModule} from "./access-profiles/access-profile-forms/access-profile-forms.module";
import {TimeModelFormsModule} from "./time-models/time-model-forms/time-model-forms.module";
import {JobsComponent} from './jobs/jobs.component';
import {JobsFormsModule} from "./jobs/jobs-forms/jobs-forms-component.module";
import {LockingDeviceDevicesModule} from "./locking-devices/locking-device-devices/locking-device-devices.module";
import {UserUsersModule} from "./users/user-users/user-users.module";
import {UserGroupsModule} from "./users/user-groups/user-groups.module";
import {LockingDeviceGroupsModule} from "./locking-devices/locking-device-groups/locking-device-groups.module";
import {SystemModule} from "./system/system.module";
import {LockingDevicesModule} from "./locking-devices/locking-devices.module";

@NgModule({
  declarations: [
    SystemComponent,
    UsersComponent,
    LockingMediaComponent,
    TimeModelsComponent,
    AccessProfilesComponent,
    LogbookComponent,
    JobsComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgbDropdownModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbNavModule,
    NgbTooltipModule,
    LightboxModule,
    TranslateModule,
    CoreModule,
    AccessProfileFormsModule,
    TimeModelFormsModule,
    JobsFormsModule,
    LockingDeviceDevicesModule,
    UserUsersModule,
    UserGroupsModule,
    NgbTypeaheadModule,
    LockingDevicesModule,
    LockingDeviceGroupsModule,
    SystemModule,
    NgbProgressbar
  ]
})
export class PagesModule {
}
