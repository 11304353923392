<form>
  <div class="row row-cols-lg-6 g-4">

    <div *ngIf="paramsManagedByGroup && paramsGroup != null" id="deviceParamsGroupWarning" class="col-lg-12">
      <div class="card shadow-none ces-bg-secondary mb-0 p-2">
        <span class="text-center">
          {{ "LOCKING_DEVICES.DEVICES.PARAMS_READONLY.PREFIX" | translate }} "{{paramsGroup.name}}"          {{ "LOCKING_DEVICES.DEVICES.PARAMS_READONLY.SUFFIX" | translate }}
        </span>
      </div>
    </div>

    <div [classList]="'col-lg-6'" >
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "LOCKING_DEVICES.DEVICES.SECTION.SETTINGS" | translate }}</h6>
        <div class="card-body pb-0">

          <div [ngClass]="{'form-floating form-floating-custom mb-3': true}">
            <input [readonly]="isReadonly" type="number" class="form-control"
                   id="openingDuration" name="openingDuration"
                   [(ngModel)]="openingDuration.value"
                   [ngClass]="{ 'invalid-feedback-ngd': !openingDuration.isValid, 'pending-changes': openingDuration.isValid && openingDuration.hasChanges }"
                   min="3" max="180">
            <label for="openingDuration">{{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="interval"></i>
            </div>
            <div *ngIf="!openingDuration.isValid" class="invalid-feedback">
              @if (!openingDuration.isSpecificValid('required')) {
                <div> {{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.REQUIRED" | translate }}</div>
              } @else if (!openingDuration.isSpecificValid('min')) {
                <div>{{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.MIN" | translate }}</div>
              } @else if (!openingDuration.isSpecificValid('max')) {
                <div>{{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.MAX" | translate }}</div>
              }
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3 switch-new-design-black">
            <div id="formBeeper" [ngClass]="{'form-control': true, 'pending-changes': beeper.hasChanges }">
              <div class="form-check form-switch form-switch-md"
                   [ngClass]="{'pointer-events-none': isReadonly}">
                <input [switchDisabled]="isReadonly" type="checkbox" class="form-check-input" id="beeper"
                       name="beeper" [(ngModel)]="beeper.value">
              </div>
            </div>
            <label for="beeper">{{ "LOCKING_DEVICES.DEVICES.FIELD.BEEPER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="audio"></i>
            </div>
          </div>

          <div *ngIf="isLicenseBusiness" class="form-floating form-floating-custom mb-3 switch-new-design-black">
            <div id="formOfficeFunction" [ngClass]="{'form-control': true, 'pending-changes': officeFunction.hasChanges }">
              <div class="form-check form-switch form-switch-md" [ngClass]="{'pointer-events-none': isReadonly}">
                <input [switchDisabled]="isReadonly" type="checkbox" class="form-check-input" id="officeFunction"
                       name="officeFunction" [(ngModel)]="officeFunction.value">
              </div>
            </div>
            <label for="officeFunction">{{ "LOCKING_DEVICES.DEVICES.FIELD.OFFICE_FUNCTION.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="office"></i>
            </div>
          </div>

          <div *ngIf="isLicenseBusiness" class="form-floating form-floating-custom mb-3">
            <date-time-picker
              [readonly]="isReadonly || !isLicenseBusiness || officeFunction.value != true"
              style="flex-grow: 1"
              titleText="LOCKING_DEVICES.DEVICES.FIELD.OFFICE_TIMEOUT.TEXT"
              [(timeValue)]="officeTime.value"
              [invalidTime]="isLicenseBusiness && !officeTime.isValid"
              [showChanges]="officeTime.hasChanges && officeFunction.value == true">
            </date-time-picker>
          </div>
          <div *ngIf="isLicenseBusiness && !officeTime.isValid" class="invalid-feedback error-message d-block">
            <div style="position: absolute">{{ "LOCKING_DEVICES.DEVICES.FIELD.OFFICE_TIMEOUT.MIN" | translate }}</div>
          </div>

          <div *ngIf="isOnlineAvailable" class="form-floating form-floating-custom">
            <select *ngIf="!isReadonly; else variant"  class="form-select" id="variant">
              <option [ngValue]="variantId.value"
                      [ngClass]="{ 'invalid-feedback-ngd': !variantId.isValid, 'pending-changes': variantId.isValid && variantId.hasChanges }"
                      *ngFor="let variant of VARIANT" [value]="variant.id">{{ ("LOCKING_DEVICES.DEVICES.VARIANT." + variant.value) | translate }}</option>
            </select>
            <ng-template #variant>
              <input readonly id="variant" class="form-control" [value]="variantForTranslation | translate">
            </ng-template>
            <label for="variant">{{ "LOCKING_DEVICES.DEVICES.FIELD.VARIANT.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="online"></i>
            </div>
          </div>

          <div *ngIf="showLogging" class="form-floating form-floating-custom mt-3">
            <input readonly type="text" class="form-control" id="logging"
                   [value]="(parseInt(loggingId.value) == 2 ? 'LOCKING_DEVICES.DEVICES.LOGGING.ON' : 'LOCKING_DEVICES.DEVICES.LOGGING.OFF') | translate">
            <label for="logging">{{ "LOCKING_DEVICES.DEVICES.FIELD.LOGGING.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="log"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="isLicenseBusiness" class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "LOCKING_DEVICES.DEVICES.SECTION.TIME_MODELS" | translate }}</h6>
        <div class="card-body">

          <div class="form-floating form-floating-custom mb-3">
            <select *ngIf="!isReadonly; else openTimeModelRef" [(ngModel)]="openTimeModel.value"
                    [ngClass]="{'form-select': true, 'invalid-feedback-ngd': !openTimeModel.isValid, 'pending-changes': openTimeModel.isValid && openTimeModel.hasChanges }"
                    id="openTimeModel" name="openTimeModel">
              <option [selected]="timeModel.uuid == openTimeModel.value"
                      *ngFor="let timeModel of timeModelsData" [value]="timeModel.uuid">{{timeModel.shortName}}</option>
            </select>
            <ng-template #openTimeModelRef>
              <input readonly id="openTimeModel" class="form-control" [value]="timeModelName(_selectedItem?.openTimeModelUuid)">
            </ng-template>
            <label for="openTimeModel">{{ "LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.OPEN" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="check"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <select *ngIf="!isReadonly; else closeTimeModelRef" [(ngModel)]="closeTimeModel.value"
                    [ngClass]="{'form-select': true, 'invalid-feedback-ngd': !closeTimeModel.isValid, 'pending-changes': closeTimeModel.isValid && closeTimeModel.hasChanges }"
                    id="closeTimeModel" name="closeTimeModel">
              <option *ngFor="let timeModel of timeModelsData"
                      [value]="timeModel.uuid">{{timeModel.shortName}}</option>
            </select>
            <ng-template #closeTimeModelRef>
              <input readonly id="closeTimeModel" class="form-control" [value]="timeModelName(_selectedItem?.closeTimeModelUuid)">
            </ng-template>
            <label for="closeTimeModel">{{ "LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.CLOSE" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="cross"></i>
            </div>
          </div>

          <div *ngIf="!openTimeModel.isValid || !closeTimeModel.isValid" class="d-block invalid-feedback">
            <div>{{ "LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.EQUAL" | translate }}</div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form>
